import React, { useEffect, useState, useRef } from 'react';
import { gsap, Power1 } from 'gsap';

import AcButton from '@atoms/ac-button/ac-button.web.js';
import AbpCardBack from '@assets/images/abp-kanskaart-achter.svg';
import Coin from '@assets/images/coin.svg';
import classNames from 'classnames';

const _CLASSES = {
  main: 'ac-cards-modal',
  wrapper: 'ac-cards-modal__wrapper',
  front: 'ac-cards-modal__front',
  back: 'ac-cards-modal__back',
  title: 'ac-cards-modal__title',
  text: 'ac-cards-modal__text',
  counter: 'ac-cards-modal__counter',
  coin: 'ac-cards-modal__coin',
};
const root = document.documentElement;
const body = document.body;

const getAbsolutePosition = el => {
  var { top, left, height, width } = el.getBoundingClientRect();

  var scrollTop = window.pageYOffset || root.scrollTop || body.scrollTop || 0;
  var scrollLeft =
    window.pageXOffset || root.scrollLeft || body.scrollLeft || 0;

  var clientTop = root.clientTop || body.clientTop || 0;
  var clientLeft = root.clientLeft || body.clientLeft || 0;

  return {
    top: Math.round(top + scrollTop - clientTop),
    left: Math.round(left + scrollLeft - clientLeft),
    height,
    width,
  };
};

const cloneTransition = (fromEl, toEl) =>
  new Promise(resolve => {
    const clone = fromEl.cloneNode(true);

    const from = getAbsolutePosition(fromEl);
    const { left, top, width, height } = getAbsolutePosition(toEl);

    gsap.set([fromEl, toEl], { visibility: 'hidden' });
    gsap.set(clone, { position: 'absolute', margin: 0 });

    body.appendChild(clone);

    gsap.set(clone, from);
    gsap.to(clone, 0.5, {
      x: left - from.left,
      y: top - from.top,
      width,
      height,
      borderRadius: '42px',
      ease: Power1.easeOut,
      onComplete: () => {
        gsap.set(toEl, { visibility: 'visible' });
        body.removeChild(clone);
        resolve();
      },
    });
  });

const AcCardsModal = ({ card, onComplete }) => {
  if (!card) return null;
  const $main = useRef();
  const { cardEl: $card, cardInfo } = card;
  const [isFlipped, doFlip] = useState(false);
  const nextStep = () => {
    const points = cardInfo.value;
    onComplete(points);
  };
  useEffect(() => {
    if ($card) {
      cloneTransition($card, $main.current).then(doFlip(true));
    }
  }, [$card]);
  return (
    <div ref={$main} className={classNames(_CLASSES.main, { isFlipped })}>
      <div className={_CLASSES.wrapper}>
        <div className={_CLASSES.back}>
          <img src={AbpCardBack} alt="card background" />
        </div>
        <div className={_CLASSES.front}>
          <h2>{cardInfo.title}</h2>
          <p className={_CLASSES.text}>{cardInfo.text}</p>
          {cardInfo && (
            <section className={_CLASSES.counter}>
              <h2>
                {Math.sign(cardInfo.value) === 1 && '+'}
                {cardInfo.value}
              </h2>
              <Coin className={_CLASSES.coin} />
            </section>
          )}
          <AcButton theme="action" color="medium" onClick={nextStep}>
            Ga verder
            <span className="arrow arrow-animate ac-icon--chevron-right"></span>
          </AcButton>
        </div>
      </div>
    </div>
  );
};

export default AcCardsModal;
