import React from 'react';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import AcIcon from '@atoms/ac-icon/ac-icon';
import GoldCoin from './assets/coin-gold.svg';
import RedCoin from './assets/coin-red.svg';
import AcButton from '../../atoms/ac-button/ac-button.web';
import backGround from './assets/desktop-bg.png';

const AcCatchingCoinsEnd = ({
  store: { settings },
  score,
  redCoins,
  goldCoins,
  onComplete
}) => {
  const classes = {
    wrapper: classNames('ac-catching-coins-end__wrapper'),
    content: classNames('ac-catching-coins-end__content'),
    iconWrapper: classNames('ac-catching-coins-end__icon-wrapper'),
    scoreContainer: classNames('ac-catching-coins-end__score-container'),
    score: classNames('ac-catching-coins-end__score'),
    scoreIcon: classNames('ac-catching-coins-end__score-icon'),
    yourScore: classNames('ac-catching-coins-end__your-score'),
    yourScoreNumber: classNames('ac-catching-coins-end__your-score-number'),
  };

  const addScore = () => {
    settings.setScore(score);
    onComplete(score);
  };

  return (
    <div
      className={classes.wrapper}
      style={{ backgroundImage: `url("${backGround}")` }}
    >
      <div className={classes.content}>
        <div>
          <span className={classes.iconWrapper}>
            <AcIcon icon="check" />
          </span>
        </div>
        <div className={classes.scoreContainer}>
          <div className={classes.score}>
            <GoldCoin className={classes.scoreIcon} />
            <span>{goldCoins}</span>
          </div>
          <div className={classes.score}>
            <RedCoin className={classes.scoreIcon} />
            {redCoins}
          </div>
        </div>
        <div className={classes.totalScore}>
          <p className={classes.yourScore}>Jouw score:</p>
          <p className={classes.yourScoreNumber}>{score}</p>
        </div>
        <AcButton theme="action" callback={addScore}>
          { !!(score) && 'Verzilver je punten'}
          { !(score) && 'Ga verder'}
          <span className="arrow arrow-animate ac-icon--chevron-right"></span>
        </AcButton>
      </div>
    </div>
  );
};

export default inject('store')(observer(AcCatchingCoinsEnd));
