import React from 'react'

import classNames from 'classnames'

const _CLASSES = {
  main: 'ac-options-bar-option',
  active: 'ac-options-bar-option--active',
  highlight: 'ac-options-bar-option--highlight'
}

const AcRangeOptionsOption = ({value, label, active, highlight, onClick, disabled}) => {
  const classes = classNames(
    _CLASSES.main,
    {[_CLASSES.active]: active},
    {[_CLASSES.highlight]: highlight}
  )

  return (
    <button 
      className={classes}
      disabled={disabled && 'disabled'}
      onClick={(event) => onClick(event, value)}>
    </button>
  )
}

export default AcRangeOptionsOption