
import React, { useEffect } from 'react'

import AcButton from '@atoms/ac-button/ac-button'

const _CLASSES = {
  wrapper: 'ac-finish-confirm'
}

const AcFinishConfirm = ({ proceed, finish }) => {
  useEffect(() => {
    const [modal] = document.getElementsByClassName("ac-event-modal")
    modal.classList.add("medium")
    return () => modal.classList.remove("medium")
  }, [])

  return (
    <div className={_CLASSES.wrapper}>
      <h1>Weet je zeker dat je naar de finish wilt?</h1>
      <p>Je hebt nog niet alle life events gespeeld. Nog genoeg weetjes om te ontdekken dus.</p>
      <AcButton theme="action" color="medium" onClick={proceed}>Ok, ik speel door</AcButton>
      <AcButton theme="action" color="medium" onClick={()=> finish(true)}>Toch naar de finish</AcButton>
    </div>
  )
}
export default AcFinishConfirm