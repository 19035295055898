import React from 'react'

import classNames from 'classnames'

import Coin from '@assets/images/coin.svg'

import AcTipButton from '@atoms/ac-tip-button/ac-tip-button'
import AcQuizIcon from './ac-quiz-icon'

const _CLASSES = {
  main: 'ac-quiz-explanation',
  list: 'ac-quiz-explanation__list',
  title: 'ac-quiz-explanation__title',
  item: 'ac-quiz-explanation__item',
  icon: 'ac-quiz-explanation__icon',
  iconrotate: 'ac-quiz-explanation__icon--rotate',
  text: 'ac-quiz-explanation__text',
}

const tipVerb = (('ontouchstart' in window) || (navigator.msMaxTouchPoints > 0)) ? 'tikken' : 'klikken'

const AcQuizExplanation = ({factCatcherIcon, factCatcherName}) => {
  const items = [
    {
      text: '4 goede antwoorden = 8 munten',
      icon: <Coin className={_CLASSES.icon}/>
    },
    {
      text: `Verzamel de vliegende ${factCatcherName} door erop te ${tipVerb}. Elke vangst is 1 extra munt.`,
      icon: <AcQuizIcon icon={factCatcherIcon} className={classNames(_CLASSES.icon, _CLASSES.iconrotate)}/>
    },
    {
      text: 'In dit spel kan je 1 keer een tip vragen, dit kost je 1 munt.',
      icon: <AcTipButton type="remove" isStatic badge={1}/>
    },
    {
      text: 'Weet je het antwoord echt niet? We kunnen 2 foute antwoorden voor je wegstrepen. Dit kost je 1 munt.',
      icon: <AcTipButton type="tip" isStatic badge={1}/>
    }
  ]

  return (
    <div className={_CLASSES.main}>
      <div className={_CLASSES.title}>Beantwoord de vragen en scoor munten!</div>
      <ul className={_CLASSES.list}>
        {
          items.map((item, id) => {
            return (
              <li key={id} className={_CLASSES.item}>
                <div className={_CLASSES.icon}>
                  {item.icon}
                </div>
                <div className={_CLASSES.text}>{item.text}</div>
              </li>
            )
          })
        }
      </ul>
    </div>
  )
}

export default AcQuizExplanation