import React from 'react'

import classNames from 'classnames'

import AcIcon from '@atoms/ac-icon/ac-icon.web'


const _CLASSES = {
  main: 'ac-quiz-nav'
}

const AcQuizNav = ({label, onClick, className, disabled}) => {
  const getClasses = () => classNames(_CLASSES.main, className && className)
  return (
    <button 
      onClick={onClick}
      disabled={disabled && "disabled"}
      className={getClasses()}>
        {label}
        <AcIcon icon="chevron-right"/>
    </button>
  )
}

export default AcQuizNav