import React from 'react'
import classNames from "classnames";

const _CLASSES = {
    main: 'ac-finish-enquete',
    iframe: 'ac-finish-enquete__iframe',
}

const AcFinishEnquete = () => {
    return (
        <div className={classNames(_CLASSES.main)}>
            <iframe className={classNames(_CLASSES.iframe)} src="https://smi.eu.qualtrics.com/jfe/form/SV_bPjCjPNGXcOR4yy"title="Enquete"></iframe>
        </div>
    )
}

export default AcFinishEnquete