// Imports => React
import React, { useEffect, useMemo } from 'react';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import { gsap } from 'gsap';
import { Switch, Route, withRouter } from 'react-router-dom';
import classNames from 'classnames';

// Imports => SCSS
import '@styles/index.scss';

import { SOUNDS } from '@constants'

// Imports => Views
import AcWorldMap from '@views/ac-world-map/ac-world-map.web';
import AcSplashScreen from '@views/ac-splash-screen/ac-splash-screen.web';
// Imports => Atoms
import AcErrorBoundary from '@atoms/ac-error-boundary/ac-error-boundary.web';
import AcJukebox from './utilities/ac-jukebox'

const Jukebox = new AcJukebox(SOUNDS)

const _CLASSES = {
  ROOT: 'ac-root',
  MAIN: 'ac-app',
  ROUTESECTION: 'ac-route__section',
};

const App = ({ store, history }) => {
  const { location } = history;
  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    gsap.set('.ac-route__section', { css: { opacity: 0 } });
    gsap.to('.ac-route__section', { opacity: 1, duration: 1, ease: 'swift' });
  };

  history.listen((location, action) => {
    init();
  });
  // const handleRouteChanged = event => {};

  const getRouteSectionClassNames = useMemo(() => {
    return classNames(_CLASSES.ROUTESECTION);
  }, []);

  const getRootClassNames = useMemo(() => {
    return classNames(_CLASSES.ROOT);
  }, []);

  return (
    <AcErrorBoundary screen={location.pathname}>
      <div className={getRootClassNames}>
        <section className={getRouteSectionClassNames} id={'ac-scroller'}>
          <Switch location={location}>
            <Route
              path="/acworldmap"
              name={'AcWorldMap'}
              component={() => <AcWorldMap jukebox={Jukebox} />}
            />
            <Route name={'AcSplashScreen'} component={AcSplashScreen} />
          </Switch>
        </section>
      </div>
    </AcErrorBoundary>
  );
};

export default withRouter(inject('store')(observer(App)));
