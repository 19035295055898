import React from 'react'

import AcIcon from '@atoms/ac-icon/ac-icon'
import AcQuizIcon from './ac-quiz-icon'
import AcButton from '@atoms/ac-button/ac-button.web.js'
import Coin from '@assets/images/coin.svg'

import classNames from 'classnames'

const _CLASSES = {
  main: 'ac-quiz-end',
  lottie: 'ac-quiz-end__lottie',
  card: 'ac-quiz-end__card',
  checkIcon:'ac-quiz-end__check-icon',
  text: 'ac-quiz-end__text',
  content: 'ac-quiz-end__content',
  score: 'ac-quiz-end__score',
  scoreTitle: 'ac-quiz-end__score-title', 
  scoreColumn: 'ac-quiz-end__score-column',
  scoreItem: 'ac-quiz-end__score-item',
  scoreIcon: 'ac-quiz-end__score-icon',
  scoreValue: 'ac-quiz-end__score-value',
  scoreIconRotate: 'ac-quiz-end__score-icon--rotate',
}

const AcQuizEnd = ({quiz, coins, onClick, factsCatched}) => {
  const {endScreenText, endScreenImage, factCatcherIcon} = quiz

  const getStyle = () => {
    return {
      backgroundImage: `url(${endScreenImage})`
    }
  }
  return (
    <div className={_CLASSES.main} style={getStyle()}>
      <div className={_CLASSES.content}>
        <div className={_CLASSES.card}>
          <div>
            <span className={_CLASSES.checkIcon}>
              <AcIcon icon="check"/>
            </span>
          </div>
          <div className={_CLASSES.text}>
            { `${(coins + factsCatched) ? 'Lekker bezig! ' : ''} ${endScreenText}` }
          </div>

          
          <h1 className={_CLASSES.scoreTitle}>Tussenstand</h1>
          <div className={_CLASSES.score}>
            
            <div className={_CLASSES.scoreColumn}>
              <div className={_CLASSES.scoreItem}>
                <span className={_CLASSES.scoreValue}>{coins}</span>
                <Coin className={_CLASSES.scoreIcon}/>
              </div>
              Score uit vragen
            </div>
            <div className={_CLASSES.scoreColumn}>
              <div className={_CLASSES.scoreItem}>
                <span className={_CLASSES.scoreValue}>{factsCatched}</span>
                <AcQuizIcon icon={factCatcherIcon} className={classNames(_CLASSES.scoreIcon, _CLASSES.scoreIconRotate)}/>
              </div>
              Goed gevangen
            </div>
          </div>
            <AcButton onClick={onClick} className="ac-button--action">
              { !!(coins + factsCatched) && 'Verzilver je punten'}
              { !(coins + factsCatched) && 'Ga verder'}
              <span className="arrow arrow-animate ac-icon--chevron-right"></span>
            </AcButton>
        </div>
      </div>
      
    </div>
  )
}

export default AcQuizEnd