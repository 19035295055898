import React, { useState } from 'react'
import AcCard from './ac-cards-card'
import AcCardsModal from './ac-cards-modal'

import cards from '@api/chance-cards.json'

import classNames from 'classnames'

const _CLASSES = {
  main: 'ac-cards-frame',
  header: 'ac-cards-frame__header',
  content: 'ac-cards-frame__content',
  title: 'ac-cards-frame__title',
  explainer: 'ac-cards-frame__explainer'
}

const shuffleArray = arr => arr
  .map(a => [Math.random(), a])
  .sort((a, b) => a[0] - b[0])
  .map(a => a[1]);

const CARDS = shuffleArray(cards)

const generateCards = ({ setCard, setDisabled, disabled, onComplete }) => {
  let cards = []
  for (let key = 0; key < CARDS.length; key++) {
    const onSelect = (card) => {
      setDisabled(true)
      setCard({ cardEl: card, cardInfo: CARDS[key] })
    }
    cards.push(<AcCard {...{ key, onSelect, disabled, onComplete }} />)
  }
  return cards;
}

const AcCardsFrame = ({ onComplete }) => {
  const [card, setCard] = useState(null)
  const [disabled, setDisabled] = useState(false)
  return (
    <div className={classNames(_CLASSES.main, { disabled })}>
      <AcCardsModal {...{ card, onComplete }} />
      <div className={_CLASSES.header}>
        <h1 className={_CLASSES.title}>De kans van je leven</h1>
        <p className={_CLASSES.explainer}>
          Veel zaken kun je van tevoren regelen, maar vaak loopt het leven toch net wat anders dan gepland.
          De kans dat er iets onverwachts gebeurt (negatief of positief) is best reëel. En dat kan ook invloed hebben op je pensioen. 
          Benieuwd? Kies een kanskaart en ervaar het zelf!
        </p>
      </div>

      <div className={_CLASSES.content}>
        {generateCards({ setCard, setDisabled, disabled })}
      </div>
    </div>

  )
}

export default AcCardsFrame