import React, {useState} from 'react'
// import classNames from 'classnames'

import AcOptionsBar from '@atoms/ac-options-bar/ac-options-bar'
import AcQuizNav from './ac-quiz-nav'

const _CLASSES = {
  main: 'ac-quiz-question-range',
  label: 'ac-quiz-question-range__label',
  divider:'ac-quiz-question-range__divider',
  button: 'ac-quiz-question-range__button',
  optionsbar: 'ac-quiz-question-range__options-bar',
  instruct: 'ac-quiz-question-toggles__instruct'
}

const AcQuizQuestionRange = ({answer, label, options, onUpdate}) => {
  const [selected, setSelected] = useState(null)
  const [answered, setAnswered] = useState(false)
  const onUpdateHandler = (value) => {
    setSelected(value)
  }
  const onClickHandler = () => {
    onUpdate(selected)
    setAnswered(true)
  }

  return (
    <div className={_CLASSES.main}>
      <div className={_CLASSES.label}>{label}</div>
      <AcOptionsBar
        disabled={answered} 
        className={_CLASSES.optionsbar}
        onUpdate={onUpdateHandler} 
        options={options}/>
      <hr className={_CLASSES.divider}></hr>
      <a className={_CLASSES.instruct}>Geef aan welk antwoord goed is.</a>
      { <AcQuizNav
          disabled={!selected || answered}
          onClick={onClickHandler} 
          className={_CLASSES.button} 
          label="Antwoord invoeren"/> }
    </div> 
  )
}

export default AcQuizQuestionRange