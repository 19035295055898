import React from 'react';
import gameImage from './assets/game-image.png';

import classNames from 'classnames';
import AcButton from '../../atoms/ac-button/ac-button.web';

const _CLASSES = {
  main: classNames('ac-quiz-intro'),
  header: classNames([
    'ac-quiz-intro__header',
    'ac-quiz-intro__header--minigame',
  ]),
  content: classNames('ac-quiz-intro__content'),
  introMargin: classNames('h-margin-bottom-50'),
  alignCenter: classNames('h-flex-v-align-center'),
  marginRight: classNames('h-margin-right-10'),
  colorBlue: classNames('h-text--color-alpha'),
};

const AcFlappyBirdIntro = props => {
  const { nextAction } = props;

  const headerStyle = {
    backgroundImage: `url(${gameImage})`,
  };

  return (
    <div className={_CLASSES.main}>
      <div style={headerStyle} className={_CLASSES.header} />
      <div className={_CLASSES.content}>
        <h3>Fladder je fit</h3>
        <div className={_CLASSES.introMargin}>
          <p>
            Stilstaan bij later is belangrijk, maar let ook nu goed op jezelf. Blijf bewegen en ontspan genoeg naast je werk. Zo fladder je fluitend door het leven en start je fit aan je pensioen.
          </p>
          <p>
            Fladder door zoveel mogelijk openingen in de muurtjes. Laat het hartje vliegen door op het scherm te tikken of klikken. Bij elke tik of klik vliegt het hartje iets hoger. Pas op dat je niet tegen de muurtjes botst of het hartje laat neerstorten!
          </p>
          <ul>
            <li>Bots je ergens tegenaan, dan start je weer vanaf het begin.</li>
            <li>De beste score uit 3 pogingen telt.</li>
            <li>Let op: het fladderen begint zodra je start.</li>
          </ul>
        </div>
        <AcButton callback={nextAction} theme="action">
          Start
          <span className="arrow arrow-animate ac-icon--chevron-right"></span>
        </AcButton>
      </div>
    </div>
  );
};

export default AcFlappyBirdIntro;
