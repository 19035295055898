import { observable, computed, action } from 'mobx';

// Imports => Constants
import { KEYS } from '@constants';

// Imports => Utilities
import { AcAutoLoad, AcSaveState } from '@utils';

const app = {};

export class SettingsStore {
  constructor(store) {
    AcAutoLoad(this, KEYS.SETTINGS);

    app.store = store;
    app.config = store.config;
  }

  @observable
  settings = {
    avatar: null,
    name: '',
    score: null,
    active_event: null,
  };

  @computed
  get current_settings() {
    return this.settings;
  }

  @action
  setScore = score => {
    this.settings.score = score;
    AcSaveState(KEYS.SETTINGS, this.settings);
  };

  @action
  set = (target, value) => {
    return new Promise(resolve => {
      if (!target) return;
      if (!this[target]) return;
      if (typeof value === 'undefined' || value === null) return;

      this[target] = {
        ...this[target],
        ...value,
      };
      AcSaveState(target, this[target]);
      resolve();
    });
  };

  @action
  setValue = (target, property, value) => {
    return new Promise(resolve => {
      if (!target) return;
      if (!this[target]) return;
      if (!property) return;
      if (typeof value === 'undefined' || value === null) return;

      this[target][property] = value;
      AcSaveState(target, this[target]);
      resolve();
    });
  };
}

export default SettingsStore;
