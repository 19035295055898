import React, { useState, useRef, useEffect } from 'react'

import AcOptionsBarOption from './ac-options-bar-option'
import classNames from 'classnames'

const _CLASSES = {
  main: 'ac-options-bar',
  list: 'ac-options-bar__list',
  item: 'ac-options-bar__item',
  tracker: 'ac-options-bar__tracker',
  track: 'ac-options-bar__track',
  disabled: 'ac-options-bar--disabled'
}

const AcOptionsBar = ({options, onUpdate, className, disabled}) => {
  const tracker = useRef()
  const track = useRef()


  const [value, setValue] = useState(null)

  const onClickHandler = (event, val) => {
    setValue(val)
    onUpdate(val)
  }

  useEffect(() => {
    if(!value) return

    const index = options.findIndex(a => a === value)
    const trackerPartLength = Math.floor(tracker.current.clientWidth / (options.length - 1))
    
    const width = trackerPartLength * index

    track.current.style.width = `${width}px`
  }, [value])

  const classes = classNames(
    _CLASSES.main,
    className && className,
    disabled && _CLASSES.disabled
  )

  return (
    <div  className={classes}>
      <ul className={_CLASSES.list}>
        <div ref={tracker} className={_CLASSES.tracker}>
          <div ref={track} className={_CLASSES.track}/>
        </div>
        { options.map(option => {
            return (
              <li 
                className={_CLASSES.item}
                key={option}>
                
                <AcOptionsBarOption
                  disabled={disabled}
                  active={option === value}
                  highlight={value && option < value}
                  value={option}
                  label={option}
                  onClick={onClickHandler}/>
                {option}
              </li>
            )
          }) }
      </ul>
    </div>
  )
}

export default AcOptionsBar