import React, { useState } from 'react'
import AcButton from '@atoms/ac-button/ac-button.web';

import classNames from 'classnames'

const _CLASSES = {
  main: 'ac-quiz-question-truefalse',
  list: 'ac-quiz-question-truefalse__list',
  item: 'ac-quiz-question-truefalse__item',
  pressed: 'ac-button--pressed'
}

const AcQuizQuestionTrueFalse = ({options, answer, onUpdate}) => {
  const [selected, setSelected] = useState(false)
  const clickHandler = (value) => {
    setSelected(value)
    onUpdate(value)
  }

  return (
    <div className={classNames(_CLASSES.main)}>
      <ul className={classNames(_CLASSES.list)}>
        { 
          Object.keys(options).map(key => {
            return (
              <li key={key} className={classNames(_CLASSES.item)}>
                <AcButton 
                  color="medium"
                  disabled={!!selected}
                  onClick={() => clickHandler(key)} 
                  className={classNames('ac-button--action', selected === key && _CLASSES.pressed)}>
                  {options[key]}
                  <span className="arrow arrow-animate ac-icon--chevron-right"/>
                </AcButton>
              </li>
            )
          })
        }
      </ul>
    </div>
  )  
}

export default AcQuizQuestionTrueFalse
