import React, { useState, useEffect, useRef } from 'react'

import classNames from 'classnames'

import AcQuizIntro from './ac-quiz-intro'
import AcQuizFrame from './ac-quiz-frame'
import AcQuizNav from './ac-quiz-nav'
import AcQuizEnd from './ac-quiz-end'

import AcQuizQuestionToggles from './ac-quiz-question-toggles'
import AcQuizQuestionChoice from './ac-quiz-question-choice'
import AcQuizQuestionTrueFalse from './ac-quiz-question-truefalse'
import AcQuizQuestionRange from './ac-quiz-question-range'

import AcTipButton from '@atoms/ac-tip-button/ac-tip-button'
import AcTipModal from './ac-quiz-tip-modal'
import AcQuizFactCatcher from './ac-quiz-fact-catcher'

const _LABELS = {
  START: 'Start',
  NEXT: 'Volgende'
}

const _CLASSES = {
  main: 'ac-quiz',
  answered: 'ac-quiz--answered',
  wrapper: 'ac-quiz__wrapper',
  footer: 'ac-quiz__footer',
  question: 'ac-quiz__question',
  title: 'ac-quiz__title',
  column: 'ac-quiz__column',
  button: 'ac-quiz__button',
  answertitle: 'ac-quiz__answer-title',
  tipbuttons: 'ac-quiz__tip-buttons',
  tipbutton: 'ac-quiz__tip-button'
}

const AcQuiz = ({quiz, onComplete, jukebox}) => {
  const [ currentQuestion, setCurrentQuestion ] =  useState(-1)
  const [ currentAnswer, setCurrentAnswer ] = useState(null)
  const [ answerStatus, setAnswerStatus ] = useState('start')
  const [ tips, setTips ] = useState(1)
  const [ tip, setTip ] = useState(null)
  const [ fact, setFact ] = useState(null)
  const [ removes, setRemoves ] = useState(1)
  const [ remove, setRemove ] = useState(false)
  const [ coins, setCoins ] = useState(0)
  const [ factsCatched, setFactsCatched ] = useState(0)

  const quizElement = useRef()

  const updateAnswerHandler = (value) => {
    setCurrentAnswer(value)
  }

  const updateCurrentQuestion = () => {
    if(answerStatus !== 'neutral') {
      setAnswerStatus('neutral')
      setRemove(false)

      setCurrentQuestion(current => (current+1))
    }
  }

  const onCompleteHandler = () => {
    onComplete(coins + factsCatched)
  }

  const isButtonDisabled = () => {
    if(answerStatus !== 'neutral' || currentQuestion === -1) return false
    return true
  }

  const onRemoveHandler = () => {
    if(removes < 1) return
    setRemove(true)
    setCoins(current => current - 1)
    setRemoves(current => current - 1)
  }

  const onShowTipHandler = () => {
    if(tips < 1) return
    
    setCoins(current => current - 1)
    setTips(current => current - 1)

    setTip(quiz.questions[currentQuestion].tip)
  }

  const onFactCatchHandler = () => {
    const { facts } = quiz
    setFactsCatched(current => current + 1)
    setFact(facts[currentQuestion])
  }

  const onCloseTipHandler = () => {
    setTip(null)
  }

  useEffect(() => {
    if(!currentAnswer) return
    
    const { answer } = quiz.questions[currentQuestion]

    if(currentAnswer.toString() !== answer.toString()) {
      setAnswerStatus('wrong')
      jukebox.playSound('WRONG')
    } else {
      setAnswerStatus('correct')
      jukebox.playSound('RIGHT')
      setCoins(current => current + 2)
    }
  }, [currentAnswer])

  const getQuestionType = () => {
    const { questionType, questionLabel, answer, options } = quiz.questions[currentQuestion]

    const questionProps = { answer, options, onUpdate: updateAnswerHandler }
    
    switch(questionType) {
      case 'toggles': return <AcQuizQuestionToggles {...questionProps}/> 
      case 'choice': return  <AcQuizQuestionChoice {...questionProps} remove={remove}/>
      case 'truefalse': return <AcQuizQuestionTrueFalse {...questionProps}/>
      case 'range': return <AcQuizQuestionRange {...questionProps} label={questionLabel}/>
      default:
    }
  }

  const getExplanation = () => {
    const { explanation, answerSummary } = quiz.questions[currentQuestion]

    if(answerStatus === 'neutral') return null

    return (
      <>
        { answerStatus === 'wrong' && <div className={classNames(_CLASSES.answertitle)}>Dat is fout! {answerSummary}</div> }
        { answerStatus === 'correct' && <div className={classNames(_CLASSES.answertitle)}>Dat klopt!</div> }
        <div>
          { explanation }
        </div>
      </>
    )
  }

  const getQuestion = () => {
    const { title  } = quiz.questions[currentQuestion]
    
    return (
      <> 
        <div className={_CLASSES.title} dangerouslySetInnerHTML={{__html: title}}/>

        <div className={_CLASSES.question}>
          <div className={_CLASSES.column}>
            { getQuestionType() }
          </div>

          <div className={_CLASSES.column}>
            { getExplanation() }
          </div>
        </div>
      </>
    )
  }

  const getTipButtons = () => {
    if(answerStatus !== 'neutral') return null

    const { questionType } = quiz.questions[currentQuestion]
    return (
      <ul className={_CLASSES.tipbuttons}>
        { questionType === 'choice' && 
          <li className={_CLASSES.tipbutton}>
            <AcTipButton 
              onClick={onRemoveHandler} 
              disabled={removes < 1} 
              type="remove"
              title="Streep 2 foute antwoorden weg"
              badge={removes}/>
          </li> 
        }
        { questionType !== 'truefalse' && 
          <li className={_CLASSES.tipbutton}>
            <AcTipButton 
              onClick={onShowTipHandler}
              type="tip" 
              title="Klik hier voor een tip"
              disabled={tips < 1}
              badge={tips}/>
          </li>
        }
      </ul>
    )
  }

  const getLayout = () => {
    const { questions } = quiz
    if(currentQuestion === -1) {
      return <AcQuizIntro 
        quiz={quiz} 
        onStart={updateCurrentQuestion} />
    } else if(currentQuestion >=0 && currentQuestion < questions.length) {
      return (
        <AcQuizFrame quiz={quiz} answerStatus={answerStatus}>
          { getQuestion() }
          { getTipButtons() }
        </AcQuizFrame> 
      )
    } else {
      return <AcQuizEnd 
        onClick={onCompleteHandler} 
        quiz={quiz}
        factsCatched={factsCatched}
        coins={coins}/>
    }
  }

  const getMainClasses = () => classNames(
    _CLASSES.main,
    answerStatus !== 'neutral' && _CLASSES.answered
  )
  return (
    <div ref={quizElement} className={getMainClasses()}>
      { currentQuestion >=0 && currentQuestion < quiz.questions.length &&
        <AcQuizFactCatcher 
          question={currentQuestion}
          quizElement={quizElement.current}  
          icon={quiz.factCatcherIcon}
          onCatch={onFactCatchHandler}
        />
      }
      <AcTipModal active={tip} onClose={onCloseTipHandler}>
        <h1>Tip</h1>
        {tip}
      </AcTipModal>
      <AcTipModal active={fact} onClose={() => {setFact(null)}}>
        <h1>Goed gevangen!</h1>
        <h2>Weetje:</h2>
        {fact}
      </AcTipModal>
        { getLayout() }
        { currentQuestion < quiz.questions.length &&
          <div className={_CLASSES.footer}>
            <AcQuizNav
              className={_CLASSES.button}
              onClick={updateCurrentQuestion} 
              disabled={isButtonDisabled()}
              label={currentQuestion === -1 ? _LABELS.START : _LABELS.NEXT}/>
          </div>
        }
    </div>
  )
}

export default AcQuiz