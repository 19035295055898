import React from 'react'

import classNames from 'classnames'
import AcIcon from '@atoms/ac-icon/ac-icon.web.js'

const _CLASSES = {
  main: 'ac-choice-button',
  selected: 'ac-choice-button--selected',
  content: 'ac-choice-button__content',
  icon: 'ac-choice-button__icon',
  transparent: 'ac-choice-button--transparent'
}

const AcChoiceButton = ({selected, transparent, children, icon, iconColor, onClick, disabled}) => {

  const getMainClasses = () => {
    return classNames(
      _CLASSES.main,
      {[_CLASSES.selected]: selected},
      {[_CLASSES.transparent]: transparent}
    )
  }

  return (
    <button 
      onClick={onClick} 
      className={getMainClasses()}
      disabled={disabled && 'disabled'}
      >
      <div className={_CLASSES.content}>
        <div>
          {children}
        </div>
        <div>
          {icon && 
            <span style={{color: iconColor}} className={_CLASSES.icon}>
              <AcIcon icon={icon}/>
            </span> }
        </div>
      </div>
      
    </button>
  )
}

export default AcChoiceButton