// Imports => React
import React, { useState, memo } from 'react';
import { useHistory } from "react-router-dom";

// Imports => Constants
import { VISUALS } from '@constants';

// Imports => Componets
import { AcHeader, AcModal } from '@components';

// Imports => Atoms
import AcButton from '@atoms/ac-button/ac-button.web';

// Imports => Views
import AcOnboardingSteps from '@components/ac-onboarding/ac-onboarding';

const _CONFIG = {
  CLASSES: {
    MAIN: 'ac-splash-screen',
  },
  SLIDEDIRECTION: 'up', // posible options: up, down, left and right
  MODALTYPE: 'ac-modal--inscreen', // posible options: --inscreen and --fullscreen
};

const AcSplashScreen = ({ SignPost = VISUALS.SIGNPOST }) => {
  const [showmodal, setShowModal] = useState(false);
  const { push } = useHistory()
  
  const openModal = () => setShowModal(!showmodal);

  const onCompleteHandler = () => {
    setShowModal(false)
    push('/acworldmap')
  }
  return (
    <>
      <AcHeader show={!showmodal} />
      <div className={_CONFIG.CLASSES.MAIN}>
        <SignPost />
        <AcButton theme="action" callback={openModal}>
          Start
          <span className="arrow arrow-animate ac-icon--chevron-right"></span>
        </AcButton>
      </div>
      <AcModal
        className={showmodal ? 'ac-modal ac-modal--active' : 'ac-modal '}
        active={showmodal}
        modalState={showmodal}
        modalType={_CONFIG.MODALTYPE}
        slideDirection={_CONFIG.SLIDEDIRECTION}
        content={<AcOnboardingSteps onComplete={onCompleteHandler} />}
      />
    </>
  );
};

export default memo(AcSplashScreen);
