import React from 'react';
import classNames from 'classnames';

import AcButton from '@atoms/ac-button/ac-button.web';
import HeartWings from "@assets/images/worldmap/heart-wings.svg"

import backGround from '@components/ac-catching-coins/assets/desktop-bg.png';

const AcFlappyBirdScore = ({
  scores,
  next
}) => {
  const classes = {
    wrapper: classNames('ac-catching-coins-end__wrapper'),
    content: classNames('ac-catching-coins-end__content'),
    iconWrapper: classNames('ac-catching-coins-end__icon-wrapper'),
    yourScore: classNames('ac-catching-coins-end__your-score'),
    yourScoreNumber: classNames('ac-catching-coins-end__your-score-number'),
  };

  const currentScore = scores[scores.length - 1]
  const tries = scores.length
  const highscore = Math.max(...scores)

  const getHeartClass = (index) => (index < tries ? 'ac-flappy-bird__heart-done' : 'ac-flappy-bird__heart-unused')
  const getNextTag = () => (tries === 3 ? highscore ? 'Verzilver je punten' : 'Volgende' : 'Probeer nogmaals')

  return (
    <div
      className={classes.wrapper}
      style={{ backgroundImage: `url("${backGround}")` }}
    >
      <div className={classes.content}>
        <div className="ac-flappy-bird__lives">
          <HeartWings className={getHeartClass(0)} />
          <HeartWings className={getHeartClass(1)} />
          <HeartWings className={getHeartClass(2)} />
        </div>
        <div>
          <p className={classes.yourScore}>Score:</p>
          <p className={classes.yourScoreNumber}>{currentScore}</p>
        </div>
        <div>
          <p className={classes.yourScore}>Beste score:</p>
          <p className={classes.yourScoreNumber}>{highscore}</p>
        </div>
        <AcButton theme="action" callback={() => next(currentScore)}>
          {getNextTag()}
          <span className="arrow arrow-animate ac-icon--chevron-right"></span>
        </AcButton>
      </div>
    </div>
  );
};

export default AcFlappyBirdScore