// Get ENV variables
const ENV = process.env;

export const config = {
	api: {
		timeout: 1000 * 60,
		maxContentLength: 10000,
	},
	rollbar: {
		accessToken: ENV.ROLLBAR_KEY,
		captureUncaught: true,
		captureUnhandledRejections: true,
		verbose: false,
		payload: {
			environment: ENV.ROLLBAR_ENVIRONMENT,
		},
	},
	ENV,
};

export default config;
