import React, { useMemo, useEffect } from 'react';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';

import AcCard from '@atoms/ac-card/ac-card';
import AcButton from '@atoms/ac-button/ac-button';
import AcFinishHeader from './ac-finish-header';
import AcFinishFooter from './ac-finish-footer';
import AcFinishEnquete from './ac-finish-enquete';

import { AVATARS } from '@data/avatars.data';

import classNames from 'classnames';

const _CLASSES = {
  main: 'ac-finish-frame',
  flag: 'ac-finish-frame__flag',
  confetti: 'ac-finish-frame__confetti',
  avatar: 'ac-finish-frame__avatar',
  wrapper: 'ac-finish-frame__wrapper',
};

const AcCardsFrame = ({ coins, store, jukebox }) => {
  const { current_settings } = store.settings;

  useEffect(() => {
    jukebox.playSound('ENDING')
  }, [])

  const renderAvatar = useMemo(
    () => {
      const avatarName = current_settings.avatar.name;
      const AvatarImg = AVATARS.filter(av => av.name === avatarName);
      const Avatar = AvatarImg[0].image;
      return <Avatar className={_CLASSES.avatar} />;
    },
    [current_settings.avatar]
  );

  return (
    <div className={classNames(_CLASSES.main)}>
      <AcFinishHeader coins={coins} />
      <AcCard>
        <div className={_CLASSES.wrapper}>
          {renderAvatar}
          <h2>Bedankt voor het spelen {current_settings.name}!</h2>
          <p>
            Je hebt een mooie score neergezet. Als het goed is weet je nu nog
            beter hoe je jouw pensioen zo goed mogelijk kunt regelen. En wil je
            nou meer informatie of jouw eigen pensioen direct bekijken? Maak dan
            de volgende stap in de reis van je leven!
          </p>
          <AcButton
            tag="a"
            href="https://www.abp.nl/werkgevers/handige-tools/reis-van-je-leven/de-volgende-stap.aspx?ns_mchannel=game&ns_campaign=fitpensioen_wg&ns_source=direct&ns_linkname=devolgendestap"
            theme="action"
            color="medium"
          >
            Naar de volgende stap
            <span className="arrow arrow-animate ac-icon--chevron-right" />
          </AcButton>
            <AcFinishEnquete />
        </div>
      </AcCard>
      <AcFinishFooter />
    </div>
  );
};

export default inject('store')(observer(AcCardsFrame));
