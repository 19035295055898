// Imports => Constants
import { VISUALS } from '@constants';

// Imports => Utilties
import { AcUUID } from '@utils';

const CHARACTERS = [
  { name: 'Lotte', gender: ['zij', 'haar'] },
  { name: 'Pim',  gender: ['hij', 'zijn'] },
  { name: 'Zarah', gender: ['zij', 'haar'] },
  { name: 'Reda', gender: ['hij', 'zijn'] },
  { name: 'Zoe', gender: ['zij', 'haar'] },
  { name: 'Edwin', gender: ['hij', 'zijn'] }
];

const generateAvatars = () => {
	const len = CHARACTERS.length;
	let n = 0;
	let result = [];

	for (n; n < len; n++) {
		const name = CHARACTERS[n].name;
		const gender = CHARACTERS[n].gender;
		const upp = name.toUpperCase();

		const obj = {
			id: AcUUID(),
      name,
      gender,
			image: VISUALS.AVATARS.IMAGES[upp],
			animations: {
				default: VISUALS.AVATARS.ANIMATIONS[upp].DEFAULT,
				correct: VISUALS.AVATARS.ANIMATIONS[upp].CORRECT,
				wrong: VISUALS.AVATARS.ANIMATIONS[upp].WRONG,
			},
		};

		result.push(obj);
	}

	return result;
};

export const AVATARS = generateAvatars();
