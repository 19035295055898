// Imports => React
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { gsap } from 'gsap';
import { Draggable } from 'gsap/all';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';

// Imports => Constants
import { VISUALS } from '@constants';

// Imports => Utilities
import { AcUUID } from '@utils';

// Imports => Data
import { AVATARS } from '@data/avatars.data';

import markers from '@api/markers';
import lifeEvents from '@api/life-events';
import miniGames from '@api/mini-games';

import AcQuiz from '@components/ac-quiz/ac-quiz';
import AcCards from '@components/ac-cards/ac-cards';
import AcFinish from '@components/ac-finish/ac-finish-frame';
import AcFinishConfirm from '@components/ac-finish/ac-finish-confirm';
import AcMinigame from '@components/ac-minigame/ac-minigame';
import AcEventModal from '@components/modal/ac-event-modal';
import AcCoinCounter from '@atoms/ac-coin-counter/ac-coin-counter';
import AcSoundToggle from '@atoms/ac-sound-toggle/ac-sound-toggle';

// Register GSAP plugin
gsap.registerPlugin(MotionPathPlugin);

const _CLASSES = {
  MAIN: 'ac-world-map',
  VIEWPORT: 'ac-world-map__viewport',
};

let animations = {};
let instance = {
  location: 'a',
  anim: null,
};

const AcWorldMap = ({
  store,
  history,
  WorldMap = VISUALS.WORLDMAP,
  startingPosition = { from: 'a', to: 'b' },
  jukebox,
}) => {
  const [currentEvent, setCurrentEvent] = useState(null);
  const [completedMarkers, setCompletedMarkers] = useState([]);
  const [coins, setCoins] = useState(0);
  const [forcedFinish, setForcedFinish] = useState(false);
  const { current_settings } = store.settings;
  const { WORLDMAPARTWORK: WorldMapArtwork } = VISUALS;
  useEffect(() => {
    gsap.registerPlugin(Draggable);
    jukebox.setMute(false);
    jukebox.playSound('BACKGROUND', true);
    if (!current_settings.avatar) {
      const { replace } = history;
      if (replace) replace('/');
    } else {
      init();
    }
  }, []);

  useEffect(() => {
    const { MARKERCHECKORANGE, MARKERCHECKBLUE } = VISUALS;
    completedMarkers.forEach(id => {
      const { event } = markers.find(({ id: markerId }) => markerId === id);

      const $el = document.querySelector(`#ac-world-map g[id^="${id}"]`);
      $el.innerHTML =
        event === 'lifeEvent' ? MARKERCHECKORANGE : MARKERCHECKBLUE;
    });

    const markerElements = Array.from(
      document.querySelectorAll('#ac-world-map g[id^="em-"]')
    );

    const listeners = markerElements.map(element => {
      return {
        target: element,
        fn: () => {
          handleMarkerClick(
            element,
            !completedMarkers.includes(element.getAttribute('id'))
          );
        },
      };
    });

    listeners.forEach(({ target, fn, id }) => {
      target.addEventListener('click', fn, false);
      target.addEventListener('touchstart', fn, false);
    });

    return () => {
      listeners.forEach(({ target, fn, id }) => {
        target.removeEventListener('click', fn, false);
        target.removeEventListener('touchstart', fn, false);
      });
    };
  }, [instance, completedMarkers]);

  const init = async () => {
    await addTweens();
    await setStartingPosition();

    instance.anim = gsap.to('#avatar', {});
  };

  const generateTween = (path_id, target = '#avatar', dur) => {
    const id = AcUUID();
    const $elem = document.getElementById(path_id);
    const length = $elem.getTotalLength();
    const duration = dur ? dur : length ? calculateTravelSpeed(length, 200) : 1;

    // Do not use onComplete here, because it will be overwritten in function 'play'
    const anim = gsap.to(target, {
      id,
      motionPath: {
        path: `#${path_id}`,
        align: `#${path_id}`,
        alignOrigin: [0.5, 0.5],
      },
      duration,
      ease: dur ? 'linear' : 'power1.inOut',
      paused: true,
    });

    return anim;
  };

  const setStartingPosition = () => {
    return new Promise(resolve => {
      animations[startingPosition.from][startingPosition.to].anim.progress(0);
      instance.location = startingPosition.from;

      resolve();
    });
  };

  const calculateTravelSpeed = (distance, pixelsPerSecond) => {
    const duration = distance / pixelsPerSecond;
    return duration;
  };

  const addTweens = () => {
    return new Promise(resolve => {
      const $paths = document.querySelectorAll(
        '#ac-world-map path[id^="path-"]'
      );
      // Generates animation routes for every path
      let n = 0;
      const len = $paths.length;

      for (n; n < len; n++) {
        const $el = $paths[n];
        const id = $el.getAttribute('id');
        const arr = id.split('-');
        const from = arr[1];
        const to = arr[2];

        const anim = generateTween(id);

        if (!animations[from]) animations[from] = {};
        if (animations[from]) {
          animations[from][to] = {
            id: AcUUID(),
            from,
            to,
            anim,
            reverse: false,
          };
        }

        if (!animations[to]) animations[to] = {};
        if (animations[to]) {
          animations[to][from] = {
            id: AcUUID(),
            from: to,
            to: from,
            anim,
            reverse: true,
          };
        }
      }
      resolve();
    });
  };
  const easeInSpiritHelper = (from = null) => {
    if (!from) {
      jukebox.togglePauseSound('BACKGROUND', false, false);
      jukebox.togglePauseSound('INTRO', false, true);
      from = 'g';
    }
    const locations = ['b', 'c', 'd', 'e', 'f', 'g'];
    locations.splice(locations.indexOf(from), 1);
    const to = locations[Math.round(Math.random() * locations.length - 1)];
    if (!animations[from][to]) return easeInSpiritHelper(from);

    const {
      anim: {
        vars: {
          motionPath: { path },
        },
      },
      reverse,
    } = animations[from][to];
    const tween = generateTween(path.replace('#', ''), '#__jukebox', 2.5);
    tween.vars.onUpdate = () => {
      if (!document.getElementById('__jukebox')) return;
      if (Draggable.hitTest('#avatar', '#__jukebox') && !window.reloading) {
        alert(atob(window.hitMsg));
        window.reloading = true;
        window.location.reload();
      }
    };
    reverse ? tween.progress(1).reverse() : tween.progress(0).play();
    setTimeout(() => easeInSpiritHelper(to), 2500);
  };
  const play = useCallback(
    location =>
      new Promise(resolve => {
        // Check if animation is playing
        const case1 = instance.anim && instance.anim.isActive();
        // Check if avatar is already on location
        const case2 = instance.location === location;
        // Check if route path animation exists
        const case3 =
          !animations[instance.location] ||
          !animations[instance.location][location];
        // Do not play if one of the cases returns true
        if (case1 || case2 || case3) return;

        // This is the chosen object with Tween path animation
        const obj = animations[instance.location][location];

        // The chosen path route animation will resolve the promise when onComplete is fired
        // Note that the animation can have only one onComplete function
        // Note that onComplete will not fire when animation is played in reverse
        if (obj.reverse) obj.anim.vars.onReverseComplete = () => resolve();
        else obj.anim.vars.onComplete = () => resolve();

        instance = {
          location,
          anim: obj.reverse
            ? obj.anim.progress(1).reverse()
            : obj.anim.progress(0).play(),
        };
      }),
    [instance]
  );

  const handleMarkerEvent = markerId => {
    window.scrollTo(0, 0);
    document.body.classList.add('has-modal');
    const markerEvent = markers.find(({ id }) => id === markerId);

    if (!markerEvent) return;
    setCurrentEvent({ ...markerEvent, markerId });
  };

  const handleMarkerClick = useCallback(
    ($marker, showEvent) => {
      const id = $marker.getAttribute('id');
      const arr = id.split('-');
      const location = arr[1];
      const player = play(location);
      player.then(() => {
        if (!!document.getElementById('__jukebox')) {
          if (id === 'em-g') {
            window.reloading = true;
            setCoins(Infinity);
            setForcedFinish(true);
            handleMarkerEvent(id);
          }
        } else showEvent && handleMarkerEvent(id);
      });
    },
    [instance]
  );

  const closeModalHandler = () => {
    setCurrentEvent(null);
    document.body.classList.remove('has-modal');
    jukebox.togglePauseSound('BACKGROUND', true, true);
    // jukebox.togglePauseSound('GAMEBACKGROUND', true, false)
  };

  const onEventCompleteHandler = value => {
    setCoins(current => current + value);
    setCompletedMarkers(current => [...current, currentEvent.markerId]);
    closeModalHandler();
    if (Math.sign(coins + value) === -1) {
      play('a');
      jukebox.easeInSpirit(easeInSpiritHelper);
    }
  };

  const getEventModalContent = () => {
    const toggleSounds = () => {
      jukebox.togglePauseSound('BACKGROUND', true, false);
      // jukebox.togglePauseSound('GAMEBACKGROUND', true, true)
    };
    if (!currentEvent) return null;
    switch (currentEvent.event) {
      case 'lifeEvent':
        toggleSounds();
        const lifeEvent = lifeEvents.find(
          ({ id }) => id === currentEvent.eventId
        );
        return (
          <AcQuiz
            onComplete={onEventCompleteHandler}
            quiz={lifeEvent}
            jukebox={jukebox}
          />
        );
      case 'miniGame':
        toggleSounds();
        const miniGame = miniGames.find(
          ({ id }) => id === currentEvent.eventId
        );
        return (
          <AcMinigame
            game={miniGame}
            onComplete={onEventCompleteHandler}
            jukebox={jukebox}
          />
        );
      case 'chanceEvent':
        toggleSounds();
        return <AcCards onComplete={onEventCompleteHandler} />;
      case 'finishEvent':
        if (completedMarkers.length <= 6 && !forcedFinish) {
          return (
            <AcFinishConfirm
              proceed={closeModalHandler}
              finish={setForcedFinish}
            />
          );
        } else return <AcFinish {...{ coins, jukebox }} />;
      default:
    }
  };

  const renderAvatar = useMemo(() => {
    if (!current_settings.avatar) return null;
    const avatarName = current_settings.avatar.name;
    const AvatarImg = AVATARS.filter(av => av.name === avatarName);
    const Avatar = AvatarImg[0].image;
    return <Avatar />;
  }, [current_settings.avatar]);

  const getMainClassNames = useMemo(() => {
    return classNames(_CLASSES.MAIN);
  }, []);

  return (
    <div id={'ac-world-map'} className={getMainClassNames}>
      <div className={_CLASSES.VIEWPORT}>
        <AcCoinCounter value={coins} />
        <AcSoundToggle jukebox={jukebox} />
      </div>
      <AcEventModal
        onClose={closeModalHandler}
        show={currentEvent}
        jukebox={jukebox}
      >
        {getEventModalContent()}
      </AcEventModal>
      <WorldMapArtwork id={'worldmap-artwork'} />
      <WorldMap id={'worldmap'} />
      <div id={'avatar'}>{renderAvatar}</div>
    </div>
  );
};

export default withRouter(inject('store')(observer(AcWorldMap)));
