import React, { useState } from 'react';
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import classnames from 'classnames'

import AcSlider from '@atoms/ac-slider/ac-slider'
import AcButton from '@atoms/ac-button/ac-button.web';

import AcOnboardingWelcome from './ac-onboarding-welcome'
import AcOnboardingAvatar from './ac-onboarding-avatar'
import AcOnboardingPlay from './ac-onboarding-play'

const _CLASSES = {
  MAIN: 'ac-onboarding',
  NAV: 'ac-onboarding__nav'
}
const AcOnboardingSteps = ({ onComplete }) => {
  let slider = null

  const [step, setStep] = useState(0)
  const go = (n) => setStep(slider.to(n))
  const isActive = (n) => (step === n ? 'dot active' : 'dot')
  const nextHandler = () => {
    if (step < 2) go(step + 1)
    else onComplete()
  }
  return (
    <div className={classnames(_CLASSES.MAIN, {'no--action': step === 1})}>
      <AcSlider setSlider={$ => slider = $} onChange={setStep}>
        <AcOnboardingWelcome />
        <AcOnboardingAvatar next={nextHandler} />
        <AcOnboardingPlay next={nextHandler} />
      </AcSlider>
      <div className={_CLASSES.NAV}>
        {step !== 1 &&
          <AcButton theme="action" color="medium" onClick={nextHandler}>
            {step < 2 && 'Volgende'}
            {step > 1 && 'Start het spel'}
            <span className="arrow arrow-animate ac-icon--chevron-right"></span>
          </AcButton>
        }
        <span>
          <button onClick={() => go(0)} className={isActive(0)}></button>
          <button onClick={() => go(1)} className={isActive(1)}></button>
          <button onClick={() => slider.dispatch()} className={isActive(2)}></button>
        </span>
      </div>
    </div>
  );
};

export default inject('store')(observer(AcOnboardingSteps));
