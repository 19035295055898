/* eslint react-app/import/no-webpack-loader-syntax: off */
import LOGO from '-!svg-react-loader!@assets/images/abp-logo-1x.svg';

/* eslint react-app/import/no-webpack-loader-syntax: off */
import WORLDMAP from '-!svg-react-loader!@assets/images/worldmap/worldmap-1x.svg';
import WORLDMAPARTWORK from '-!svg-react-loader!@assets/images/worldmap/worldmap-artwork.svg';
/* eslint react-app/import/no-webpack-loader-syntax: off */
import AcAvatarImageLotte from '-!svg-react-loader!@assets/images/avatars/avatar-image-lotte-1x.svg';
import AcAvatarImagePim from '-!svg-react-loader!@assets/images/avatars/avatar-image-pim-1x.svg';
import AcAvatarImageZarah from '-!svg-react-loader!@assets/images/avatars/avatar-image-zarah-1x.svg';
import AcAvatarImageReda from '-!svg-react-loader!@assets/images/avatars/avatar-image-reda-1x.svg';
import AcAvatarImageZoe from '-!svg-react-loader!@assets/images/avatars/avatar-image-zoe-1x.svg';
import AcAvatarImageEdwin from '-!svg-react-loader!@assets/images/avatars/avatar-image-edwin-1x.svg';

/* eslint react-app/import/no-webpack-loader-syntax: off */
import SIGNPOST from '-!svg-react-loader!@assets/images/abp-paal-los.svg';
import MARKERCHECKORANGE from '!!raw-loader!@assets/images/worldmap/marker-check-orange.svg';
import MARKERCHECKBLUE from '!!raw-loader!@assets/images/worldmap/marker-check-blue.svg';
export const VISUALS = {
	LOGO,
	SIGNPOST,
	WORLDMAP,
	WORLDMAPARTWORK,
	MARKERCHECKORANGE,
	MARKERCHECKBLUE,
	AVATARS: {
		IMAGES: {
			LOTTE: AcAvatarImageLotte,
			PIM: AcAvatarImagePim,
			ZARAH: AcAvatarImageZarah,
			REDA: AcAvatarImageReda,
			ZOE: AcAvatarImageZoe,
			EDWIN: AcAvatarImageEdwin,
		},
		ANIMATIONS: {
			LOTTE: {
				DEFAULT: null,
				CORRECT: null,
				WRONG: null,
			},
			PIM: {
				DEFAULT: null,
				CORRECT: null,
				WRONG: null,
			},
			ZARAH: {
				DEFAULT: null,
				CORRECT: null,
				WRONG: null,
			},
			REDA: {
				DEFAULT: null,
				CORRECT: null,
				WRONG: null,
			},
			ZOE: {
				DEFAULT: null,
				CORRECT: null,
				WRONG: null,
			},
			EDWIN: {
				DEFAULT: null,
				CORRECT: null,
				WRONG: null,
			},
		},
	},
};

export default VISUALS;
