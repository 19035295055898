import React from 'react'

import classNames from 'classnames'

import Remove from '@assets/images/times.svg';
import LightBulb from '@assets/images/lightbulb.svg';

const _CLASSES = {
  main: 'ac-tip-button',
  remove: 'ac-tip-button--remove',
  static: 'ac-tip-button--static',
  tip: 'ac-tip-button--tip',
  badge: 'ac-tip-button__badge',
}

const AcTipButton = ({ icon, type, amount, badge, disabled, isStatic, onClick, className, title }) => {
  const getClasses = () => {
    return classNames(
      _CLASSES.main,
      isStatic && _CLASSES.static,
      className && className,
      { [_CLASSES.remove]: type === 'remove' },
      { [_CLASSES.tip]: type === 'tip' }
    )
  }

  return (
    <button
      onClick={onClick}
      disabled={disabled ? 'disabled' : null}
      title={title}
      className={getClasses()}>
      <span className={classNames(_CLASSES.badge)}>{badge}</span>
      {type === 'tip' && <LightBulb />}
      {type === 'remove' && <><Remove /><Remove /></>}
    </button>
  )
}


export default AcTipButton