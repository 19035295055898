
import React from 'react'

import AcButton from '@atoms/ac-button/ac-button'

import Reload from '@assets/images/reload.svg';
import Facebook from '@assets/images/finish/facebook.svg';
import Whatsapp from '@assets/images/finish/whatsapp.svg';
import Linkedin from '@assets/images/finish/linkedin.svg';

const _CLASSES = {
  main: 'ac-finish-footer',
  btnReload: 'ac-finish-footer__btn-reload',
  btnExit: 'ac-finish-footer__btn-exit',
  divider: 'ac-finish-footer__divider',
  socials: 'ac-finish-footer__socials'
}

const URL = process.env.APP_URL || "https://abppensioenspel.nl"
const SAFE_URL = encodeURIComponent(URL)
const SOCIALS = {
  facebook: `https://www.facebook.com/sharer/sharer.php?u=${SAFE_URL}&amp;src=sdkpreparse`,
  linkedin: `http://www.linkedin.com/shareArticle?mini=true&url=${SAFE_URL}&title=`,
  whatsapp: `whatsapp://send?text=${URL}`
}

const AcFinishFooter = () => (
  <div className={_CLASSES.main}>
    <AcButton theme="circle" className={_CLASSES.btnReload} onClick={() => window.location.reload()}>
      <Reload />
      <label>Speel opnieuw</label>
    </AcButton>
    <hr className={_CLASSES.divider} />
    <div className={_CLASSES.socials}>
      <span>Stuur dit spel door:</span>
      <AcButton tag="a" href={SOCIALS.whatsapp} target="_blank" theme="circle" size="small">
        <Whatsapp />
      </AcButton>
      <AcButton tag="a" href={SOCIALS.facebook} target="_blank" theme="circle" size="small">
        <Facebook />
      </AcButton>
      <AcButton tag='a' href={SOCIALS.linkedin} target="_blank" theme="circle" size="small">
        <Linkedin />
      </AcButton>
    </div>
  </div >
)
export default AcFinishFooter