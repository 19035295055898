// Imports => Vendor
import axios from 'redaxios';
import config from '@config';

// Imports => API
import AuthAPI from '@api/auth.api';

export default () => {
	const Client = axios.create(config.api);

	const auth = new AuthAPI(Client, config);

	return {
		auth,
	};
};
