// Imports => React
import React, { useCallback, useMemo, memo } from 'react';
import classNames from 'classnames';

// Imports => Constants
import { VISUALS } from '@constants';

const _CLASSES = {
	MAIN: 'ac-splash-screen-header',
	TEXT: {
		MAIN: 'ac-splash-screen-header__text',
	},
	CONTAINER: {
		MAIN: 'ac-splash-screen-header__logo-container',
	},
	VISUAL: {
		MAIN: 'ac-logo__visual',
	},
};

// Component
const AcHeader = ({ Visual = VISUALS.LOGO, callback, show }) => {
	const getContainerClassNames = useCallback(() => {
		return classNames(_CLASSES.CONTAINER.MAIN);
	}, []);

	const getTextClassNames = useCallback(() => {
		return classNames(_CLASSES.TEXT.MAIN);
	}, []);

	const getStyleClassNames = useCallback(() => {
		return classNames(_CLASSES.MAIN);
	}, []);

	const renderLogo = useMemo(() => {
		let Logo = VISUALS.LOGO;
		return <Logo />;
  });
  
  const Header = ({className, children}) => 
    show ? <header className={className}>{children}</header> : <></>;

	return (
    <Header className={getStyleClassNames()}>
      <span className={getTextClassNames()}>Deze game is een initiatief van</span>
      <div className={getContainerClassNames()}>{renderLogo}</div>
    </Header>
	);
};

export default memo(AcHeader);