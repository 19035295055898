import React, { useEffect, useRef } from 'react'

import { gsap } from 'gsap'
import AcLottie from '@atoms/ac-lottie/ac-lottie'


const _CLASSES = {
  main: 'ac-quiz-fact-catcher'
}
const AcQuizFactCatcher = ({icon, quizElement, question, facts, onCatch}) => {
  const element = useRef()
  const lottieFile = `/lottie/${icon}.json`

  let appearTimeout
  let disappearTimeout
  
  const onClick = () => {
    clearTimeout(appearTimeout)
    clearTimeout(disappearTimeout)

    element.current.style.pointerEvents = 'none'
    element.current.style.opacity = 0

    onCatch()
  }

  useEffect(() => {
    const setAppearTimeout = (length, fromX, fromY, toX, toY) => {    
      appearTimeout = setTimeout(() => {
        element.current.style.opacity = 1
        element.current.style.pointerEvents = 'initial'

        gsap.fromTo(
          element.current, 
          { 
            x: fromX, 
            y: fromY, 
          }, 
          {
            x: toX,
            y: toY,
            duration: question === 0 ? 8 : 6
          }
        )
      }, length)
    }

    const setDisappearTimeout = (length) => {
      disappearTimeout = setTimeout(() => {
        element.current.style.opacity = 0
        element.current.style.pointerEvents = 'none'
      }, length);
    }
    
    const { offsetWidth: elementWidth } = element.current

    element.current.style.pointerEvents = 'none'
    element.current.style.opacity = 0
    
    const { offsetWidth, offsetHeight } = quizElement
    
    const fromX = Math.floor(Math.random() * (offsetWidth - elementWidth))
    const fromY =  0
    const toX = Math.floor(Math.random() * (offsetWidth - elementWidth))
    const toY = offsetHeight - 50
    
    const appearTimeoutLength = Math.floor(Math.random() * 10000 / 2)
    const disappearTimeoutLength = question === 0 ? appearTimeoutLength + 8000 : appearTimeoutLength + 6000

    setAppearTimeout(appearTimeoutLength, fromX, fromY, toX, toY)
    setDisappearTimeout(disappearTimeoutLength)
    

    return () => {
      clearTimeout(appearTimeout)
      clearTimeout(disappearTimeout)
    }
  }, [question])

  return (
    <span 
      ref={element} 
      className={_CLASSES.main} 
      onClick={onClick}>
      <AcLottie path={lottieFile}/>
    </span>
  )
}

export default AcQuizFactCatcher