import React, { useEffect, useRef } from 'react'
import { gsap } from 'gsap'

import AcLottie from '@atoms/ac-lottie/ac-lottie'
import Coin from '@assets/images/coin.svg'

const _CLASSES = {
  flag: 'ac-finish-header__flag',
  confetti: 'ac-finish-header__confetti',
  scoreboard: 'ac-finish-header__scoreboard',
  coin: 'ac-finish-header__coin'
}

const AcFinishHeader = ({ coins }) => {
  const scoreBoard = useRef()
  const score = useRef()
  let lottieFlag = null
  let lottieConfetti = null

  useEffect(() => {
    lottieFlag.addEventListener('complete', () => runAnimations(lottieFlag))
    lottieFlag.play()
  }, [lottieFlag])

  const runAnimations = ({ wrapper }) => {
    const [svg] = wrapper.getElementsByTagName("svg")
    let stats = { val: 0 }
    svg.classList.add("zoomOut")
    gsap.fromTo(scoreBoard.current, { transform: 'scale(0)' }, { transform: 'scale(1)', duration: .5 })
    gsap.to(stats, 2, {
      val: coins,
      roundProps: "val",
      onUpdate: () => {
        score.current.innerHTML = stats.val
      }
    })
    lottieConfetti.play()
  }

  return (
    <>
      <AcLottie
        className={_CLASSES.flag}
        path={'/lottie/finish.json'}
        loop={false}
        preserveAspectRatio='xMidYMin slice'
        speed={1.2}
        getLottie={(lottie) => lottieFlag = lottie} />
      <AcLottie
        className={_CLASSES.confetti}
        path={'/lottie/finish_confetti.json'}
        loop={true}
        autoplay={false}
        preserveAspectRatio={null}
        getLottie={(lottie) => lottieConfetti = lottie} />
      <section ref={scoreBoard} className={_CLASSES.scoreboard}>
        <h2>Jouw score: </h2>
        <span>
          <h1 ref={score}>0</h1>
          <Coin className={_CLASSES.coin} />
        </span>
      </section>
    </>
  )
}
export default AcFinishHeader