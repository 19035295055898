import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { height, width } from './common/common';
import {
  bg,
  bird0,
  bird1,
  bird2,
  fg,
  pipeN,
  pipeS,
  ready,
} from './common/Sprite';
import { states, reset } from './store/store';

const SpriteWrapper = observer(function SpriteWrapper(props) {
  const gameSprite = props.gameSprite;
  const rotate = 'rotate(' + gameSprite.rotation + 'rad)';
  const translate =
    'translate(' + gameSprite.cx + 'px,' + gameSprite.cy + 'px)';
  const ctrans =
    gameSprite.rotation == null ? translate : translate + ' ' + rotate;
  const onClickHandler =
    props.onClickHandler == null ? null : props.onClickHandler;
  var style = {
    transform: ctrans,
    position: 'absolute',
  };

  return (
    <div style={style} onClick={onClickHandler}>
      {props.children}
    </div>
  );
});

const Bg = observer(function Bg(props) {
  return <SpriteWrapper gameSprite={props.bg}> {bg} </SpriteWrapper>;
});

const Fg = observer(function Fg(props) {
  return <SpriteWrapper gameSprite={props.fg}> {fg} </SpriteWrapper>;
});

export const Bird = observer(function Bird(props) {
  let wbird;
  switch (props.bird.frame) {
    case 1:
    case 3:
      wbird = bird1;
      break;
    case 2:
      wbird = bird2;
      break;
    case 0:
    default:
      wbird = bird0;
      break;
  }

  return <SpriteWrapper gameSprite={props.bird}> {wbird} </SpriteWrapper>;
});

const Pipe = observer(function Pipe(props) {
  let wpipe;
  switch (props.pipe.type) {
    default:
    case 'N':
      wpipe = pipeN;
      break;
    case 'S':
      wpipe = pipeS;
      break;
  }

  return <SpriteWrapper gameSprite={props.pipe}> {wpipe} </SpriteWrapper>;
});

export const Splash = observer(function Splash() {
  return (
    <SpriteWrapper gameSprite={{ cx: width / 376, cy: height - 200 }} />
  );
});

export const Ready = observer(function Ready() {
  return (
    <SpriteWrapper gameSprite={{ cx: width / 2 - 87, cy: height - 380 }}>
      {' '}
      {ready}
    </SpriteWrapper>
  );
});

const App = observer(function App(props, appUpdateFrame, req) {
  const setAnimationFrames = () => {
    reset()
    window.FlappyAlive = true
    req = window.requestAnimationFrame(appUpdateFrame);
    function appUpdateFrame() {
      props.updateFrame(); //this will trigger mobx to update the view when observable value change

      if (window.FlappyAlive) req = window.requestAnimationFrame(appUpdateFrame); //rerun this function again when browser is ready to update new frame
    }
    // Forcefully kill Flappy to prevent that Flappy will escape through currently running requestAnimationFrames
    return () => { window.FlappyAlive = false }
  }
  useEffect(setAnimationFrames, []);

  const { bgs, fgs, bird, pipes } = props.store;
  const { currentstate, playerScore } = props.game;
  const { onFinishGame } = props;

  const style = {
    width: width,
    height: height,
    position: 'relative',
    overflow: 'hidden'
  };

  // Immediately call onFinishGame when Flappy dies
  useEffect(() => {
    if (currentstate === states.Score) onFinishGame(playerScore)
  }, [currentstate])

  return (
    <div className="FlappyBird" id="fakingcanvas" style={style}>
      {bgs.map(bg => (
        <Bg bg={bg} key={bg.id} />
      ))}
      {pipes.map(pipe => (
        <Pipe pipe={pipe} key={pipe.id} />
      ))}
      <Bird bird={bird} />
      {currentstate === states.Splash ? <Splash /> : null}
      {currentstate === states.Splash ? <Ready /> : null}
      {fgs.map(fg => (
        <Fg fg={fg} key={fg.id} />
      ))}
    </div>
  );
});

export default App;
