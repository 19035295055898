import React from 'react';
import AbpCardBack from '@assets/images/abp-kanskaart-achter.svg';

const _CLASSES = {
  main: 'ac-cards-card',
};

const AcCard = ({ onSelect, disabled }) => {
  const onClickHandler = ({ currentTarget: card }) => {
    card.style.animationPlayState = 'paused';
    onSelect(card);
  };
  const random = Math.random() * 0.3 + 0.15;
  const animation = {
    animation: !disabled && `wobble ${random}s infinite`,
    animationDelay: `${random}s`,
  };

  return (
    <div
      className={_CLASSES.main}
      onClick={onClickHandler}
      style={{ ...animation }}
    >
      <img src={AbpCardBack} alt="card background" />
    </div>
  );
};

export default AcCard;
