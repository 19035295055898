import React from 'react'

import FactCatcherMatch from '@assets/images/life-events/fact-catcher-match.svg'
import FactCatcherContract from '@assets/images/life-events/fact-catcher-contract.svg'
import FactCatcherRings from '@assets/images/life-events/fact-catcher-rings.svg'
import FactCatcherBandaid from '@assets/images/life-events/fact-catcher-bandaid.svg'

const FactCatcherIcons = {
  match: FactCatcherMatch,
  contract: FactCatcherContract,
  rings: FactCatcherRings,
  bandaid: FactCatcherBandaid
}

const AcQuizIcon = ({icon, className}) => {
  const IconElement = FactCatcherIcons[icon]
  return <IconElement className={className}/>
}

export default AcQuizIcon