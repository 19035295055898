import React from 'react';
import ReactDOM from 'react-dom';

import { createBrowserHistory } from 'history';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import { Router } from 'react-router-dom';
import { Provider } from 'mobx-react';

import config from '@config';
import createStore from '@stores';

import App from '@src/App';

const browserHistory = createBrowserHistory();
const routing = new RouterStore();

const store = createStore(config);
const history = syncHistoryWithStore(browserHistory, routing);

// Safari got that bookmarkbar which overlaps our application
// This workaround will set a css variable with the innerWidth value as vh (view-height)
const browser = navigator.userAgent.toLowerCase()
let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);
if (browser.indexOf("safari") !== -1) {
	window.addEventListener('resize', () => {
		vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);
	});
}

const providers = {
	routing,
	config,
	store,
};

ReactDOM.render(
	<Provider {...providers}>
		<Router history={history}>
			<App />
		</Router>
	</Provider>,
	document.getElementById('root')
);
