import React from 'react'

import Coin from '@assets/images/coin.svg'

const _CLASSES = {
  main: 'ac-coin-counter',
  coin: 'ac-coin-counter__coin',
  value: 'ac-coin-counter__value',
}

const AcCoinCounter = ({value}) => {
  return (
    <span className={_CLASSES.main}>
      <Coin className={_CLASSES.coin}/>
      <span className={_CLASSES.value}>
        {value ? value : 0}
      </span>
    </span>
  )
}


export default AcCoinCounter
