import React from 'react';

import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';

import AcHeading from '@atoms/ac-heading/ac-heading.web';

const AcOnboardingPlay = ({ store }) => {
  const { current_settings } = store.settings
  const { name } = current_settings
  return (
    <div>
      <AcHeading rank="1" theme="LIGHTBLUE">
        De reis van je leven gaat beginnen!
      </AcHeading>
      <p className="ac-onboarding__align-left">
        {`Hoi ${name},`}<br /><br />{`Daar gaan we! Test jouw pensioenkennis, verzamel munten en behaal een superscore. 
        Je bepaalt zelf welke route je volgt door op de bollen te klikken. 
        Hoe meer spellen je speelt, des te beter je straks weet hoe je jouw pensioen kunt regelen. 
        Veel plezier op de reis van je leven!`}
      </p>
      <p className="ac-onboarding__textblock h-text--semibold ac-onboarding__textblock--align-left">
        {`Start het spel en ontdek de weg naar een zonnige toekomst!`}
      </p>
    </div>
  )
}
export default inject('store')(observer(AcOnboardingPlay));