import React, { useMemo, useState } from 'react';

import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';

import AcHeading from '@atoms/ac-heading/ac-heading.web';
import AcGridContainer from '@atoms/ac-grid/ac-grid-container/ac-grid-container.web';
import AcGridRow from '@atoms/ac-grid/ac-grid-row/ac-grid-row.web';
import AcGridCol from '@atoms/ac-grid/ac-grid-column/ac-grid-column.web';
import AcButton from '@atoms/ac-button/ac-button.web';

import { AVATARS } from '@data/avatars.data';

const _CLASSNAMES = {
  avatar: 'ac-onboarding__avatar',
  avatarSelected: 'ac-onboarding__avatar-selected',
  avatarList: 'ac-onboarding__avatar-list',
  fieldset: 'ac-onboarding__fieldset',
  nameField: 'ac-onboarding__name-field',
};

const AcOnboardingAvatar = ({ store, next, setDispatcher }) => {
  const { current_settings } = store.settings;
  const { avatar, name } = current_settings;

  const [error, setError] = useState(null);

  const checkInput = () => {
    let err = '';
    if (!avatar) err = 'Kies een avatar';
    if (!avatar && !name) err += ' en vul je naam in';
    if (!name && avatar) err = 'Vul je naam in';
    setError(err);
    if (!err) next();
  };

  setDispatcher(checkInput);

  const handleAvatarClick = item => {
    if (avatar && avatar.id === item.id) return;
    store.settings.setValue('settings', 'avatar', item);
  };
  const handleInput = ({ target: { value } }) => {
    store.settings.setValue('settings', 'name', value);
  };
  useMemo(() => {
    if (error && avatar) checkInput();
  }, [error, avatar]);

  const renderAvatars = useMemo(() => {
    let result = [];
    for (let n = 0; n < AVATARS.length; n++) {
      const item = AVATARS[n];
      const { image: Visual } = item;
      const selected = avatar ? avatar.name === item.name : undefined;
      const className = classNames(
        _CLASSNAMES.avatar,
        selected && _CLASSNAMES.avatarSelected
      );

      const object = (
        <AcGridCol xs={4} sm={4} key={item.id}>
          <div className={className} onClick={() => handleAvatarClick(item)}>
            <Visual />
          </div>
        </AcGridCol>
      );

      result.push(object);
    }
    return result;
  }, [avatar, handleAvatarClick]);

  return (
    <div>
      <AcHeading rank="1" theme="LIGHTBLUE">
        Voordat we starten...
      </AcHeading>
      <p style={{ marginBottom: 0 }}>
        Kies snel hoe je er in het spel uit wilt zien en vul je naam in. Dan
        kunnen we daarna van start!
        <br />
        <br />
        Wie wil jij zijn?
      </p>
      <AcGridContainer className={_CLASSNAMES.avatarList}>
        <AcGridRow noGutters={true}>{AVATARS && renderAvatars}</AcGridRow>
      </AcGridContainer>
      <div className={_CLASSNAMES.fieldset}>
        <label htmlFor="name">Vul jouw naam in:</label>
        <input
          id="name"
          className={_CLASSNAMES.nameField}
          name="name"
          placeholder="Voornaam"
          onChange={handleInput}
          value={name}
        />
        {error && <div>{error}</div>}
      </div>
      <AcButton theme="action" color="medium" onClick={checkInput}>
        Volgende
        <span className="arrow arrow-animate ac-icon--chevron-right"></span>
      </AcButton>
    </div>
  );
};

export default inject('store')(observer(AcOnboardingAvatar));
