import React from 'react'

import classNames from 'classnames'

import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';

import AcQuizExplanation from './ac-quiz-explanation'
import AcLottie from '@atoms/ac-lottie/ac-lottie'

const _CLASSES = {
  main: classNames('ac-quiz-intro'),
  header: classNames('ac-quiz-intro__header'),
  content: classNames('ac-quiz-intro__content'),
  button: classNames('ac-quiz-intro__button', 'ac-button--action'),
  lottie: classNames('ac-quiz-intro__lottie'),
  lottieMdUp: classNames('ac-quiz-intro__lottie-md-up')
}

const AcQuizIntro = ({ quiz }) => {
  const getStyle = () => {
    return {
      backgroundImage: `url(${quiz.introBackground})`
    }
  }

  const animateLottie = (lottie) => {
    let currCount = 1
    const playCount = 2
    const duration = 3 * 1000
    lottie.play()
    const replay = () => {
      lottie.stop();
      lottie.play();
    }
    let interval = setInterval(() => {
      if (currCount === playCount) {
        clearInterval(interval); 
        lottie.pause()
      } else {
        currCount++;
        replay();
      }
    }, duration)
  }

  return (
    <div className={_CLASSES.main}>
      <div style={getStyle()} className={_CLASSES.header}>
        <AcLottie preserveAspectRatio='xMidYMid meet' className={_CLASSES.lottie} path={quiz.introLottie} autoplay={false} getLottie={animateLottie} />
        <AcLottie preserveAspectRatio='xMidYMid meet' className={_CLASSES.lottieMdUp} path={quiz.introLottieMdUp} autoplay={false} getLottie={animateLottie} />
      </div>
      <div className={_CLASSES.content}>
        <h1>{quiz.title}</h1>
        <div>{quiz.intro}</div>

        <AcQuizExplanation factCatcherIcon={quiz.factCatcherIcon} factCatcherName={quiz.factCatcherName} />
      </div>
    </div>
  )
}

export default inject('store')(observer(AcQuizIntro))