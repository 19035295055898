import React, { useState, useEffect, useCallback } from 'react';
import GameApp from './index';
import AcFlappyBirdIntro from './AcFlappyBirdIntro';
import AcFlappyBirdScore from './AcFlappyBirdScore';

const AcFlappyBird = ({ onComplete, jukebox }) => {
  const [started, setStarted] = useState();
  const [finished, setFinished] = useState();
  const [scores, setScores] = useState([]);
  const [renderKey, setRenderKey] = useState(0)

  const setModalSize = () => {
    const [modal] = document.getElementsByClassName("ac-event-modal")
    if (started && !finished) modal.classList.add("small")
    else modal.classList.remove("small")
  }

  const setCurrentScore = (score) => {
    jukebox.playSound('FLAPPY')
    setScores($ => [...$, score]);
    setRenderKey($ => ($ + 1))
    setFinished(true)
  }

  const next = (replayScore) => {
    const highscore = Math.max(...scores, replayScore)
    
    if (scores.length === 3) {
      setFinished(true)
      onComplete(highscore)
    }
    else {
      setFinished(false)
      setStarted(true)
    }
  }
  const renderApp = useCallback(() => {
    return <GameApp onFinishGame={setCurrentScore} renderKey={renderKey} />
  }, [renderKey])

  useEffect(() => {
    setModalSize()
  }, [started, finished])

  return (
    <>
      {started && !finished ? (
        renderApp()
      ) : !started && !finished ? (
        <AcFlappyBirdIntro nextAction={() => setStarted(true)} />
      ) : (
            <AcFlappyBirdScore {...{ scores, next, onComplete }} />
          )}
    </>
  );
};

export default AcFlappyBird;
