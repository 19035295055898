import React, { useState, useRef, cloneElement } from 'react';
import { gsap } from 'gsap'

const _CLASSES = {
  MAIN: 'ac-slider',
  SLIDE: 'ac-slider__slide',
}

const AcSlider = ({ children, setSlider }) => {
  const steps = []
  let [step, setStep] = useState(0)
  // Dispatcher will let parents communicate with the slide
  let dispatch = () => { }
  const genDispatch = { setDispatcher: $ => dispatch = $ }

  // This function will transition the horizontal position of every child and set step
  // Offset is the direction of the slide (1 or -1)
  const slide = (toStep) => {
    for (let i = 0; i < steps.length; i++) {
      const { ref: { current } } = steps[i]
      const from = { x: (step) * -100 + '%' }
      const to = { x: ((toStep) * -100) + '%' }
      gsap.fromTo(current, from, to)
    }
    setStep(toStep)
    return toStep
  }

  // This function will create a wrapper with ref for every child
  for (let i = 0; i < children.length; i++) {
    const Child = cloneElement(children[i], genDispatch)
    const el = (
      <div className={_CLASSES.SLIDE} ref={useRef()} key={i}>
        {Child}
      </div>
    )
    steps.push(el)
  }

  // Pass the slide function to the parent
  setSlider({
    to: (n) => slide(n),
    slide: (n) => slide(step + n),
    dispatch: (args) => dispatch(args)
  })

  // Return wrapper with childs
  return (<div className={_CLASSES.MAIN}>{steps}</div>)
}

export default AcSlider

// How to use this slider
// <AcSlider setSlider={($)=> slider = $}>
//   <Slide1 />
//   <Slide2 />
//   <Slide3 />
// </AcSlider>
// <button onClick={()=> slider.slide(-1)}>Prev</button>
// <button onClick={()=> slider.slide(+1)}>Next</button>
// <button onClick={()=> slider.to(2)}>End</button>
// <button onClick={()=> slider.dispatch(2)}>SendEvent</button>