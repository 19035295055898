// The official ABP Jukebox 🎶

var EventTarget = function() {
  this.listeners = {};
};

EventTarget.prototype.listeners = null;
EventTarget.prototype.addEventListener = function(type, callback) {
  if (!(type in this.listeners)) {
    this.listeners[type] = [];
  }
  this.listeners[type].push(callback);
};

EventTarget.prototype.removeEventListener = function(type, callback) {
  if (!(type in this.listeners)) {
    return;
  }
  var stack = this.listeners[type];
  for (var i = 0, l = stack.length; i < l; i++) {
    if (stack[i] === callback) {
      stack.splice(i, 1);
      return;
    }
  }
};

EventTarget.prototype.dispatchEvent = function(event) {
  if (!(event.type in this.listeners)) {
    return true;
  }
  var stack = this.listeners[event.type].slice();

  for (var i = 0, l = stack.length; i < l; i++) {
    stack[i].call(this, event);
  }
  return !event.defaultPrevented;
};

class Jukebox {
  constructor(initialPlaylist) {
    this.toggleCounter = 0;
    this.playlist = {};
    this.allMuted = false;

    this.addPlaylist(initialPlaylist || {});
    this.bus = new EventTarget();
  }

  setMute(value) {
    this.allMuted = value;
    const all = Object.keys(this.playlist);
    for (let id = 0; id < all.length; id++) {
      const uid = all[id];
      this.mute(uid, value);
    }
    this.bus.dispatchEvent(new CustomEvent('muted', { detail: value }));
  }
  toggleMute() {
    this.allMuted = !this.allMuted;
    const all = Object.keys(this.playlist);
    for (let id = 0; id < all.length; id++) {
      const uid = all[id];
      this.mute(uid, this.allMuted);
    }
    this.bus.dispatchEvent(new CustomEvent('muted', { detail: this.allMuted }));
    this.toggleCounter++;
    return this.allMuted;
  }
  mute(uid, value) {
    this.playlist[uid].muted = value;
  }

  addSound(uid, src) {
    const player = document.createElement('audio');
    player.src = src;
    player.setAttribute('preload', 'auto');
    player.setAttribute('controls', 'none');
    player.style.display = 'none';
    document.body.appendChild(player);
    this.playlist[uid] = player;
  }
  addPlaylist(playlist) {
    const uids = Object.keys(playlist);
    for (let id = 0; id < uids.length; id++) {
      const uid = uids[id];
      this.addSound(uid, playlist[uid]);
    }
  }
  playSound(uid, loop = false) {
    const sound = this.playlist[uid];
    if (!sound) return;
    sound.currentTime = 0;
    if (loop) sound.setAttribute('loop', 'true');
    else sound.removeAttribute('loop');

    const promise = sound.play();

    if (promise !== undefined) {
      promise.catch(() => {
        this.setMute(true);
        sound.play();
      });
    }
  }

  togglePauseSound(uid, loop = false, value = null) {
    const sound = this.playlist[uid];
    if (!sound) return;

    if (loop) sound.setAttribute('loop', 'true');
    else sound.removeAttribute('loop');

    if (value !== null) {
      if (value) sound.play();
      else sound.pause();
    } else {
      if (sound.paused) sound.play();
      else sound.pause();
    }
  }

  easeInSpirit(helper) {
    window.hitMsg = 'R2FtZSBvdmVyIQ';
    const a = async () => {
      if (this.toggleCounter < 5) return;
      const e = await fetch('/sounds/jukebox.wasm'),
        t = await e.arrayBuffer(),
        { instance: r } = await WebAssembly.instantiate(t, {
          env: {
            memory: new WebAssembly.Memory({ initial: 10, maximum: 100 }),
            abort: () => 0,
          },
        }),
        a = r.exports.soundMixer(),
        n = r.exports.strLen(),
        o = r.exports.memory.buffer,
        s = new Uint16Array(o, a, n);
      let i = '';
      for (let e = 0; e < n; e++) i += String.fromCharCode(s[e]);
      var m = new DOMParser(),
        {
          body: { firstChild: l },
        } = m.parseFromString(i, 'text/html');
      document.getElementById('ac-world-map').appendChild(l);
      document.documentElement.classList.add('jukebox');
      setTimeout(helper, 500);
    };
    a();
  }
}

export default Jukebox;
