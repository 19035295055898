import React, { useEffect, useState } from 'react'

import SoundMuted from '@assets/images/sound-muted.svg'
import SoundPlay from '@assets/images/sound-play.svg'

const _CLASSES = {
  main: 'ac-sound-toggle',
  mainRight: 'ac-sound-toggle right'
}

const AcSoundToggle = ({ jukebox, right = false }) => {
  if (!jukebox) return null
  const [playing, _setPlaying] = useState(true)

  const setPlaying = ({ detail }) => _setPlaying(!detail)

  useEffect(() => {
    jukebox.bus.addEventListener('muted', setPlaying)
    return () => jukebox.bus.removeEventListener('muted', setPlaying)
  })


  const toggle = () => {
    jukebox.toggleMute()
  }
  return (
    <span className={right ? _CLASSES.mainRight : _CLASSES.main} onClick={toggle}>
      {playing ? < SoundPlay /> : <SoundMuted />}
    </span>
  )
}

export default AcSoundToggle
