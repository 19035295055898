import React, { useEffect } from 'react';
import App from './App';
import {
  store,
  updateFrame,
  birdjump,
  game,
  states,
  rungame,
  reset,
} from './store/store';

export default function GameApp(props) {
  const renderGame = () => {
    const onFinishGame = score => {
      props.onFinishGame(score);
      reset();
    };
    return (
      <App
        {...{
          store,
          updateFrame,
          game,
          onFinishGame,
          renderKey: props.renderKey,
        }}
      />
    );
  };

  const onpress = () => {
    switch (game.currentstate) {
      default:
      case states.Splash:
        rungame();
        birdjump(store.bird);
        break;
      case states.Game:
        birdjump(store.bird);
        break;
      case states.Score:
        break;
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', onpress, false);
    document.addEventListener('touchstart', onpress, false);

    return () => {
      document.removeEventListener('mousedown', onpress, false);
      document.removeEventListener('touchstart', onpress, false);
    };
  });

  return renderGame();
}
