import React, { useEffect, useState } from 'react';
import AcCatchingCoinsIntro from './ac-catching-coins-intro';
import CatchingCoins from './ac-catching-coins.class';
import { ReactComponent as Background } from './assets/desktop-bg.svg';
import AcCatchingCoinsEnd from './ac-catching-coins-end';

const AcCatchingCoins = ({ onComplete, parent, jukebox, gameRef }) => {
  const [started, setStarted] = useState();
  const [finished, setFinished] = useState();
  const [player, setPlayer] = useState();
  const windowWidth = window.innerWidth;
  const [size, setSize] = useState();
  const [isMobile, setIsMobile] = useState();

  const finishGame = scores => {
    setFinished(true);
    setPlayer(scores);
  };

  useEffect(() => {
    started && new CatchingCoins({ gameRef, finish: finishGame, mobile: isMobile, jukebox });
  }, [started]);

  useEffect(() => {
    if (windowWidth < 992) {
      const { height, width } = parent.current.getBoundingClientRect()
      setSize({
        width,
        height
      });
      setIsMobile(true);
    } else {
      setSize({
        width: 850,
        height: 616,
      });
      setIsMobile(false);
    }
  }, [windowWidth, parent]);

  const getBackgroundStyles = () => {
    return isMobile
      ? { backgroundImage: Background, position: 'absolute', top: 0, backgroundSize: 'cover' }
      : { backgroundImage: Background };
  };

  const getForegroundStyles = () => {
    return isMobile
      ? {
        position: 'absolute',
        top: 0,
      }
      : {
        position: 'absolute',
        bottom: 0,
      };
  };

  return (
    <>
      {started && !finished ? (
        <>
          <canvas
            id="backgroundCanvas"
            {...size}
            style={getBackgroundStyles()}
          ></canvas>
          <canvas id="canvas" {...size} style={getForegroundStyles()}></canvas>
        </>
      ) : !started && !finished ? (
        <AcCatchingCoinsIntro nextAction={() => setStarted(true)} />
      ) : (
            <AcCatchingCoinsEnd {...player} onComplete={onComplete} />
          )}
    </>
  );
};

export default AcCatchingCoins;
