const SOUNDS = {
  BACKGROUND: '/sounds/background-bloom.mp3',
  GAMEBACKGROUND: '/sounds/loop-bloom.mp3',
  COIN: '/sounds/coin-catched.mp3',
  FLAPPY: '/sounds/flappy-over.mp3',
  RIGHT: '/sounds/answer-right.mp3',
  WRONG: '/sounds/answer-wrong.mp3',
  INTRO: 'sounds/intro.mp3',
  ENDING: '/sounds/game-ending.mp3'
};

export default SOUNDS;
