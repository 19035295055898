import coinGold from './assets/coin-gold.png';
import coinRed from './assets/coin-red.png';
import coinCatcher from './assets/coinCatcher.png';
import desktopBG from './assets/desktop-bg.png';

class CatchingCoins {
  constructor(props) {
    this.canvas = document.getElementById('canvas');
    this.context = this.canvas.getContext('2d');
    this.canvasBack = document.getElementById('backgroundCanvas');
    this.contextBack = this.canvasBack.getContext('2d');
    this.finishGame = props.finish;
    this.screenHalf = window.innerWidth / 2;
    this.isMobile = props.mobile;
    this.runningEvent = null;
    this.jukebox = props.jukebox;

    this.timeTotal = 30;
    //Timer for the Timeout - needed in order to clear it
    // this.timer;

    // this.gameTime;
    this.timerStart = 0;

    //Keeps track of hi score
    this.hiscore = 0;

    //Background image, music track, and arrays of sounds.
    //Arrays are needed so that the same sounds
    //can overlap with each other
    this.background = new Image();
    this.background.src = desktopBG;

    this.smashCounter = 0;

    // this.player;
    this.fruits = [];
    this.numberOfFruits = 15;
    props.gameRef.current = () => {
      this.player.gameOver = true;
      clearTimeout(this.gameTime);
      clearTimeout(this.timer);
      window.removeEventListener('keydown', this.keyDownEvent);
      window.removeEventListener('pointerdown', this.pointerDownEvent);
      window.removeEventListener('pointerout', this.pointerOutEvent);
      window.removeEventListener('pointerup', this.pointerOutEvent);
    };

    this.init();
  }

  //Player constructor
  Player = ({ canvas, context }) => {
    const self = {};
    self.gameOver = false;
    self.score = 0;
    self.coinsCaught = 0;
    self.goldCoins = 0;
    self.redCoins = 0;
    self.fruitsMissed = 0;
    self.playerWidth = this.isMobile ? 120 : 150;
    self.playerHeight = this.isMobile ? 80 : 128;
    self.playerSpeed = 15;
    self.x = canvas.width / 2;
    self.y = canvas.height - self.playerHeight;
    self.playerImage = new Image();
    self.playerImage.src = coinCatcher;
    self.playerImage.width = self.playerWidth;
    self.playerImage.height = self.playerHeight;

    //Draws the player
    self.render = () => {
      context.drawImage(
        self.playerImage,
        self.x,
        self.y,
        (self.playerImage.width = self.playerWidth),
        (self.playerImage.height = self.playerHeight)
      );
    };

    //Moves the player left
    self.moveLeft = () => {
      if (self.x > 0) {
        self.x -= self.playerSpeed;
      }
    };

    //Moves the player right
    self.moveRight = () => {
      if (self.x < canvas.width - self.playerWidth) {
        self.x += self.playerSpeed;
      }
    };
    return self;
  };

  //Fruit constructor
  Fruit({ player, canvas, context, smashCounter }) {
    const self = {};

    self.fruitNumber = Math.floor(Math.random() * 2);
    self.fruitType = '';
    self.fruitScore = 0;
    self.fruitWidth = 50;
    self.fruitHeight = 50;
    self.fruitImage = new Image();
    self.fruitSpeed = Math.floor(Math.random() * 4 + 2);
    self.x = Math.random() * (canvas.width - self.fruitWidth);
    self.y = Math.random() * -canvas.height - self.fruitHeight;

    //Creates a different kind of fruit depending on the fruit number
    //which is generated randomly
    self.chooseFruit = () => {
      if (self.fruitNumber === 0) {
        self.fruitImage.src = coinRed;
        self.fruitType = 'coinRed';
        self.fruitScore = -1;
      } else {
        self.fruitImage.src = coinGold;
        self.fruitType = 'coinGold';
        self.fruitScore = 1;
      }
    };

    //Makes the fruit descend.
    //While falling checks if the fruit has been caught by the player
    //Or if it hit the floor.
    self.fall = () => {
      if (self.y < canvas.height - self.fruitHeight) {
        self.y += self.fruitSpeed;
      } else {
        if (smashCounter === 4) {
          smashCounter = 0;
        } else {
          smashCounter++;
        }
        player.fruitsMissed += 1;
        self.changeState();
        self.chooseFruit();
      }
      self.checkIfCaught();
    };

    //Checks if the fruit has been caught by the player
    //If it is caught, the player score and fruit counter is increased, and
    //the current fruit changes its state and becomes a different fruit.
    self.checkIfCaught = () => {
      if (self.y >= player.y) {
        if (
          (self.x > player.x && self.x < player.x + player.playerWidth) ||
          (self.x + self.fruitWidth > player.x &&
            self.x + self.fruitWidth < player.x + player.playerWidth)
        ) {
          player.score += self.fruitScore;
          player.coinsCaught += 1;

          if (self.fruitType === 'coinRed') {
            player.redCoins += 1;
          } else {
            this.jukebox.playSound('COIN');
            player.goldCoins += 1;
          }

          self.changeState();
          self.chooseFruit();
        }
      }
    };

    //Randomly updates the fruit speed, fruit number, which defines the type of fruit
    //And also changes its x and y position on the canvas.
    self.changeState = () => {
      self.fruitNumber = Math.floor(Math.random() * 5);
      self.fruitSpeed = Math.floor(Math.random() * 3 + 1);
      self.x = Math.random() * (canvas.width - self.fruitWidth);
      self.y = Math.random() * -canvas.height - self.fruitHeight;
    };

    //Draws the fruit.
    self.render = () => {
      context.drawImage(self.fruitImage, self.x, self.y);
    };

    return self;
  }

  //Adds controls. Left arrow to move left, right arrow to move right.
  //ENTER to restart only works at the game over screen.

  pointerDownEvent = e => {
    const move = ({ clientX }) => {
      if (clientX < this.screenHalf) {
        this.player.moveLeft();
      } else {
        this.player.moveRight();
      }
    };
    move(e);
    this.runningEvent = setInterval(() => move(e), 100);
  };
  pointerOutEvent = () => {
    if (this.runningEvent) clearInterval(this.runningEvent);
  };
  init = () => {
    const keyDownEvent = e => {
      e.preventDefault();
      if (e.keyCode === 37) {
        this.player.moveLeft();
      } else if (e.keyCode === 39) {
        this.player.moveRight();
      } else if (e.keyCode === 13 && this.player.gameOver === true) {
        main();
        window.clearTimeout(this.timer);
      }
    };
    window.addEventListener('keydown', keyDownEvent);
    window.addEventListener('pointerdown', this.pointerDownEvent);
    window.addEventListener('pointerout', this.pointerOutEvent);
    window.addEventListener('pointerup', this.pointerOutEvent);
    //Fills an array of fruits, creates a player and starts the game
    const main = () => {
      this.contextBack.font = 'bold 23px Velvetica';
      this.contextBack.fillStyle = 'WHITE';
      this.player = this.Player({ canvas: this.canvas, context: this.context });
      this.fruits = [];

      for (var i = 0; i < this.numberOfFruits; i++) {
        var fruit = this.Fruit({
          canvas: this.canvas,
          player: this.player,
          smashCounter: this.smashCounter,
          context: this.context,
        });
        fruit.chooseFruit();
        this.fruits.push(fruit);
      }
      startGame();
    };

    const startGame = () => {
      drawGame();
      updateGame();
      startCountdown();
    };

    const startCountdown = () => {
      this.timerStart = new Date().getTime();
      this.gameTime = window.setTimeout(() => {
        this.player.gameOver = true;
        window.removeEventListener('keydown', this.keyDownEvent);
        window.removeEventListener('pointerdown', this.pointerDownEvent);
        window.removeEventListener('pointerout', this.pointerOutEvent);
        window.removeEventListener('pointerup', this.pointerOutEvent);
        this.finishGame(this.player);
      }, this.timeTotal * 1000);
    };

    //Checks for gameOver and makes each fruit in the array fall down.
    const updateGame = () => {
      //   this.music.play();
      // if (this.player.fruitsMissed >= 10) {
      //   this.player.gameOver = true;
      // }

      for (var j = 0; j < this.fruits.length; j++) {
        this.fruits[j].fall();
      }
      this.timer = window.setTimeout(updateGame, 30);
    };

    //Draws the player and fruits on the screen as well as info in the HUD.
    const drawGame = () => {
      const timeLeft = Math.round(
        this.timeTotal - (new Date().getTime() - this.timerStart) / 1000
      );
      if (this.player.gameOver === false) {
        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.contextBack.clearRect(
          0,
          0,
          this.canvasBack.width,
          this.canvasBack.height
        );

        this.contextBack.drawImage(this.background, 0, 0);
        this.player.render();

        for (let j = 0; j < this.fruits.length; j++) {
          this.fruits[j].render();
        }
        this.contextBack.font = '20px Arial';
        this.contextBack.fillText(
          'Score: ' + this.player.score,
          this.canvas.width / 2 - 50,
          50
        );
        this.contextBack.font = '40px Arial';
        this.contextBack.fillText(timeLeft, this.canvas.width / 2 - 35, 140);
        // );
      } else {
        //Different screen for game over.
        for (let i = 0; i < this.numberOfFruits; i++) {
          this.fruits.pop();
        }
        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
      }
      window.requestAnimationFrame(drawGame);
    };

    main();
  };
}

export default CatchingCoins;
