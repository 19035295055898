import React from 'react';

import AcHeading from '@atoms/ac-heading/ac-heading.web';

const AcOnboardingWelcome = () => {
  return (
    <div>
      <AcHeading rank="1" theme="LIGHTBLUE" className="ac-heading--margin-bottom-5">
        Welkom bij
      </AcHeading>
      <AcHeading rank="1" variant="LARGER" theme="BLUE">
        de reis van je leven!
      </AcHeading>
      <p>
        In je leven maak je allerlei gebeurtenissen mee. Denk aan trouwen, samenwonen of een nieuwe baan. Wist je dat deze momenten ook van invloed kunnen zijn op je pensioen?
      </p>
      <p>
        Test in dit spel je kennis en ontdek wat je zelf kunt doen om jouw pensioen zo goed mogelijk te regelen!
      </p>
    </div>
  )
}
export default AcOnboardingWelcome