// Imports => API
import createApi from '@api';

// Imports => Stores
import SettingsStore from '@stores/settings.store';

class Store {
	constructor(config) {
		this.config = config;

		this.api = createApi({
			config: config,
		});

		this.settings = new SettingsStore(this);
	}
}

export default Store;
