// Imports => React
import React, { useRef, useMemo, memo } from 'react';
import classNames from 'classnames';

const _CLASSES = {
  MAIN: 'ac-button',
  SIZES: {
    DEFAULT: 'ac-button--regular',
    SMALL: 'ac-button--small',
  },
  COLORS: {
    MEDIUM: 'ac-button--medium',
    WHITE: 'ac-button--white'
  },
  THEME: {
    DEFAULT: 'ac-button--pitch',
    OUTLINE: 'ac-button--outline',
    CIRCLE : {
      MAIN: 'ac-button--circle',
      DEFAULT: 'ac-button--circle'
    },
    PITCH: {
      MAIN: 'ac-button--pitch',
      DEFAULT: '',
      OUTLINE: 'ac-button--pitch-outline',
      TRANSPARENT: 'ac-button--pitch-transparent',
    },
    ACTION: {
      MAIN: 'ac-button--action',
      DEFAULT: 'ac-button--action',
      OUTLINE: 'ac-button--action-outline',
      TRANSPARENT: 'ac-button--action-transparent',
    },
    FACEBOOK: {
      MAIN: 'ac-button--facebook',
      DEFAULT: 'ac-button--facebook',
    },
    TWITTER: {
      MAIN: 'ac-button--twitter',
      DEFAULT: 'ac-button--twitter',
    },
    INSTAGRAM: {
      MAIN: 'ac-button--instagram',
      DEFAULT: 'ac-button--instagram',
    },
    YOUTUBE: {
      MAIN: 'ac-button--youtube',
      DEFAULT: 'ac-button--youtube',
    },
  },
  BLOCK: 'ac-button--block',
  DISABLED: 'ac-button--disabled',
  LOADING: 'ac-button--loading',
  LOADER: 'ac-button__loader',
};

// Component
const AcButton = ({
  children,
  tag = 'button',
  title = 'button',
  type = 'button',
  theme = 'default',
  variant = 'default',
  color = '',
  size = 'default',
  disabled = false,
  block = false,
  loading = false,
  callback = () => { },
  className,
  ...rest
}) => {
  const $element = useRef();

  const handleClick = event => {
    if (!disabled && callback) callback(event, $element.current);
  };

  const getLoaderClassNames = useMemo(() => {
    return classNames(_CLASSES.LOADER);
  }, []);

  const getThemeAndStyleClassNames = (theme, variant, color) => {
    let className = false;

    const match = {
      theme: theme
        ? theme
          .replace('-', '')
          .replace('_', '')
          .trim()
          .toUpperCase()
        : false,
      variant: variant
        ? variant
          .replace('-', '')
          .replace('_', '')
          .trim()
          .toUpperCase()
        : false,
      color: color
        ? color
          .replace('-', '')
          .replace('_', '')
          .trim()
          .toUpperCase()
        : false,
    };
    if (match.theme && match.variant) {
      className = _CLASSES.THEME[match.theme][match.variant];
    } else if (match.theme) {
      className = _CLASSES.THEME[match.theme].MAIN;
    } else if (match.variant) {
      className = _CLASSES.THEME.OUTLINE;
    }
    if (match.color) {
      return [className, _CLASSES.COLORS[match.color]]
    } else {
      return [className]
    }
  };

  const getStyleClassNames = useMemo(() => {
    const customClasses = getThemeAndStyleClassNames(theme, variant, color)
    const vars = classNames(
      _CLASSES.MAIN,
      ...customClasses,
      size && _CLASSES.SIZES[size.toUpperCase()],
      disabled && _CLASSES.DISABLED,
      block && _CLASSES.BLOCK,
      loading && _CLASSES.LOADING,
      className
    );
    return vars 
  }, [size, disabled, block, theme, variant, color, loading, className]);

  const Tag = tag;

  return (
    <Tag
      ref={$element}
      type={type}
      aria-roledescription={'button'}
      disabled={disabled}
      onClick={handleClick}
      className={getStyleClassNames}
      {...rest}
    >
      <div className={getLoaderClassNames} />
      {children || 'Button'}
    </Tag>
  );
};

export default memo(AcButton);
