import React, { useState, useEffect, useCallback } from 'react'

// import classNames from 'classnames'

import AcChoiceButton from '@atoms/ac-choice-button/ac-choice-button.js'

const _CLASSES = {
  main: 'ac-quiz-question-choice',
  list: 'ac-quiz-question-choice__list',
  item: 'ac-quiz-question-choice__item',
}

const AcQuizQuestionChoice = ({options, answer, remove, onUpdate}) => {
  const [ selected, setSelected ] = useState(null)
  const [ questionOptions, setQuestionOptions ] = useState([])
  
  const onClickHandler = (value) => {
    setSelected(value)
    onUpdate([value])
  }

  const setSelectedQuestionOptions = useCallback((mappedOptions) => {
    if(selected === answer.toString()) {
      setQuestionOptions([mappedOptions.find(({value}) => value === answer.toString())])
    } else {
      setQuestionOptions(mappedOptions.map(option => {
        if(option.value === selected || option.value === answer.toString()) return option

        return {...option, disabled: true, transparent: true}
      }))
    }
  }, [selected])

  const setGeneralQuestionOptions = useCallback((mappedOptions) => {
    if(!remove) {
      setQuestionOptions(mappedOptions)
    } else {
      const answerOption = questionOptions.find(({value}) => value === answer[0])
      const otherOptions = questionOptions.filter(({value}) => value !== answer[0])

      let i = 0
      const usedIndexes = []

      while(i < 2) {
        const randomIndex = Math.floor(Math.random() * otherOptions.length)
        if(usedIndexes.includes(randomIndex)) continue

        Object.assign(otherOptions[randomIndex], {disabled: true, transparent: true})
        usedIndexes.push(randomIndex)
        i++
      }

      setQuestionOptions(
        [].concat(answerOption, ...otherOptions)
        .sort((a, b) => a.value < b.value ?  -1 : 1)
      )
    }
  }, [options, remove])

  useEffect(() => {
    const mappedOptions = Object.keys(options).map(key => {
      return { value: key, label: options[key], disabled: false, transparent: false}
    })
    
    if(selected) setSelectedQuestionOptions(mappedOptions)
    else setGeneralQuestionOptions(mappedOptions)

  }, [options, remove, selected])

  const getIcon = (key) => {
    if(!selected || selected !== key) return null
    
    if(answer.toString() === selected) {
      return {icon: 'check', iconColor: '#349a6d'}
    } else if(answer.toString() !== selected) {
      return {icon: 'close', iconColor: '#e50056'}
    }
  }

  const getButton = ({label, value, disabled, transparent}) => {
    return (
      <AcChoiceButton 
        {...getIcon(value)}
        transparent={transparent}
        onClick={() => onClickHandler(value)}
        disabled={disabled || selected}
        selected={selected === value}>
          <strong>{value.toUpperCase()}</strong> {label}
      </AcChoiceButton>
    )
  }
  
  return (
    <div className={_CLASSES.main}>
      <ul className={_CLASSES.list}>
        {questionOptions.map(option => {
          return (
            <li className={_CLASSES.item} 
              key={option.value}>
              { getButton(option) }
            </li>
            )
          })}
      </ul>
    </div>
  )
}

export default AcQuizQuestionChoice