export class AuthAPI {
	constructor(Client, config) {
		this.Client = Client;
		this.config = config;
	}

	request_update = () => {
		return this.Client.get(`${this.config.ENV.SERVER}/update`);
	};
}

export default AuthAPI;
