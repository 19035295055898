import React from 'react'
import classNames from 'classnames'
import { inject } from 'mobx-react';
import { observer } from 'mobx-react-lite';
import AcLottie from '@atoms/ac-lottie/ac-lottie'

const _CLASSES = {
  main: classNames('ac-quiz-frame'),
  header: classNames('ac-quiz-frame__header'),
  content: classNames('ac-quiz-frame__content'),
  title: classNames('ac-quiz-frame__title'),
  lottie: classNames('ac-quiz-frame__lottie')
}

const AcQuizFrame = ({store, quiz, answerStatus, children}) => {
  const getHeaderLottie = () => {
    const { current_settings: { avatar: {name} } } = store.settings;


    switch (answerStatus) {
      case 'correct': 
        return `/lottie/avatar_${name.toLowerCase()}_shine.json`
      case 'wrong': 
        return `/lottie/avatar_${name.toLowerCase()}_rain.json`
      default: 
        return `/lottie/avatar_${name.toLowerCase()}.json`
    }
  }

  return (
    <div className={_CLASSES.main}>
      <div style={{backgroundColor: quiz.color}} className={_CLASSES.header}>
        <AcLottie preserveAspectRatio={null} className={_CLASSES.lottie} path={getHeaderLottie()}/>
        <h3 className={_CLASSES.title}>{quiz.title}</h3>
      </div>

      <div className={_CLASSES.content}>
        {children}
      </div>       
    </div>
        
  )
}

export default inject('store')(observer(AcQuizFrame))