import React from 'react';

import classNames from 'classnames';
import AcIcon from '@atoms/ac-icon/ac-icon.web.js';
import AcSoundToggle from '@atoms/ac-sound-toggle/ac-sound-toggle'

const _CLASSES = {
  main: 'ac-event-modal',
  wrapper: 'ac-event-modal__wrapper',
  show: 'ac-event-modal--show',
  content: 'ac-event-modal__content',
  button: 'ac-event-modal__button',
  buttonIcon: 'ac-event-modal__button-icon',
};

const AcQuizModal = ({ children, show, onClose, jukebox }) => {
  const getClasses = () => {
    return classNames(_CLASSES.main, { [_CLASSES.show]: show });
  };

  return (
    <div className={getClasses()}>
      <div className={_CLASSES.wrapper}>
        <div className={_CLASSES.content}>
          {show && show.closeBtn &&
            <button onClick={onClose} className={_CLASSES.button}>
              <AcIcon className={_CLASSES.buttonIcon} icon="close" /> Sluit
            </button>
          }
          <AcSoundToggle jukebox={jukebox} right={true}/>
          {children}
        </div>
      </div>
    </div>
  );
};

export default AcQuizModal;
