import React, { useEffect, useRef } from 'react';
import AcCatchingCoins from '../ac-catching-coins/ac-catching-coins';
import AcFlappyBird from '../game-flappy-bird/AcFlappyBird';

const AcMinigame = ({ game, onComplete, jukebox }) => {
  const parent = useRef()
  const gameRef = useRef()
  const _CLASSES = {
    main: 'ac-quiz',
    wrapper: 'ac-quiz__wrapper',
  };

  useEffect(() => { return () => gameRef.current && gameRef.current() }, [])

  const getCurrentGame = () => {
    if (!game.id) return;
    switch (game.id) {
      case 'catchingCoins':
        return <AcCatchingCoins {...{ onComplete, parent, gameRef, jukebox }} />;
      case 'flappyBird':
        return <AcFlappyBird {...{ onComplete, jukebox }} />;
      default:
    }
  };

  return (
    <div className={_CLASSES.main}>
      <div className={_CLASSES.wrapper} ref={parent}>{getCurrentGame()}</div>
    </div>
  );
};

export default AcMinigame;
