import React, { useCallback } from 'react'

import AcButton from '@atoms/ac-button/ac-button.web.js'

import classNames from 'classnames'

const _CLASSES = {
  main: 'ac-quiz-tip-modal',
  active: 'ac-quiz-tip-modal--active',
  card: 'ac-quiz-tip-modal__card',
  content: 'ac-quiz-tip-modal__content',
  button: 'ac-quiz-tip-modal__button',
}

const AcQuizTipModal = ({ active, children, onClose }) => {
  
  const getClasses = useCallback(() => {
    return classNames(
      _CLASSES.main,
      { [_CLASSES.active]: active }
    )
  }, [ active ])


  const getButtonClasses = () => {
    return classNames(
      'ac-button--action',
      _CLASSES.button
    )
  }

  return (
    <div className={getClasses()}>
      <div className={_CLASSES.card}>
        <div className={_CLASSES.content}>
          { children }
        </div>
        <AcButton 
          color="medium"
          onClick={() => onClose()} 
          className={getButtonClasses()}>
            Sluit <span className="arrow arrow-animate ac-icon--chevron-right"></span>
        </AcButton>
      </div>
    </div>
  )
}


export default AcQuizTipModal