import React, { useState } from 'react'

import classNames from 'classnames'

import AcQuizNav from './ac-quiz-nav'
import Toggle from 'react-toggle'

const _CLASSES = {
  main: 'ac-quiz-question-toggles',
  button: 'ac-quiz-question-toggles__button',
  item: 'ac-quiz-question-toggles__item',
  itemdisabled: 'ac-quiz-question-toggles__item--disabled',
  itemlabel: 'ac-quiz-question-toggles__item-label',
  toggles: 'ac-quiz-question-toggles__toggles',
  toggle: 'ac-quiz-question-toggles__toggle',
  divider: 'ac-quiz-question-toggles__divider',
  instruct: 'ac-quiz-question-toggles__instruct'
}

const AcQuizQuestionToggles = ({options, answer, onUpdate}) => {
  
  const [currentAnswer, setCurrentAnswer] = useState([])
  const [selected, setSelected] = useState(false)
  
  const onChangeHandler = (e, key) => {
    if(e.target.checked) {
      setCurrentAnswer(current => [...current, key])
    } else {
      setCurrentAnswer(current => current.filter(a => a !== key))
    }
  }

  const onClickHandler = () => {
    onUpdate(currentAnswer.sort())

    setSelected(true)
  }

  const getSwitches = () => {
    return  (
      <div className={classNames(_CLASSES.toggles)}>
      {
        Object.keys(options).map(key => {
          const classes = classNames(_CLASSES.item, {[_CLASSES.itemdisabled]: selected && !answer.includes(key)})
          return (
            <div className={classes} key={key}>
              <Toggle
                className={classNames(_CLASSES.toggle)}
                name={key}
                id={key}
                label={options[key]}
                disabled={selected}
                onChange={(e) => onChangeHandler(e, key)}
                checked={currentAnswer.includes(key)}/>

                <label htmlFor={key} className={classNames(_CLASSES.itemlabel)}>{options[key]}</label>
            </div>
            )
          })
      }
      </div>
    )
  }


  return (
    <div className={classNames(_CLASSES.main)}>
      { getSwitches() }
      <hr className={_CLASSES.divider}></hr>
      <a className={classNames(_CLASSES.instruct)}>Geef aan welk antwoord goed is (meerdere antwoorden mogelijk).</a>
      <AcQuizNav 
        onClick={onClickHandler} 
        className={_CLASSES.button} 
        disabled={selected}
        label="Antwoord invoeren"/> 
    </div>       
  )
}

export default AcQuizQuestionToggles
