import React from 'react';
import gameImage from './assets/startscherm.png';
import GoldCoin from './assets/coin-gold.svg';
import RedCoin from './assets/coin-red.svg';

import classNames from 'classnames';
import AcButton from '../../atoms/ac-button/ac-button.web';

const _CLASSES = {
  main: classNames('ac-quiz-intro'),
  header: classNames(['ac-quiz-intro__header', 'ac-quiz-intro__header--minigame']),
  content: classNames('ac-quiz-intro__content'),
  introMargin: classNames('h-margin-bottom-50'),
  alignCenter: classNames('h-flex-v-align-center'),
  marginRight: classNames('h-margin-right-10'),
  colorBlue: classNames('h-text--color-alpha')
};

const AcCatchingCoinsIntro = props => {
  const { nextAction } = props;
  const headerStyle = {
    backgroundImage: `url(${gameImage})`,
  };

  return (
    <div className={_CLASSES.main}>
      <div style={headerStyle} className={_CLASSES.header} />
      <div className={_CLASSES.content}>
        <h3>Spaar je rijk</h3>
        <div className={_CLASSES.introMargin}>
          <p>
            Als je denkt dat je niet uitkomt met je pensioen, is het slim om er nog iets naast te doen. Bijvoorbeeld door extra te sparen of je hypotheek sneller af te lossen.
          </p>
          <p>
            In dit spel heb je 30 seconden de tijd om zoveel mogelijk muntjes te vangen in de portemonnee. 
            Beweeg de portemonnee door met je vinger aan de linker- en rechterkant van je scherm te tikken of door je pijltjestoetsen te gebruiken. Let op: rode muntjes zijn minpunten!
          </p>
        </div>
        <h2 className={_CLASSES.colorBlue}>Wat levert het op?</h2>
        <p className={_CLASSES.alignCenter}>
          <GoldCoin className={_CLASSES.marginRight} />
          1 gele munt = 1 punt
        </p>
        <p className={_CLASSES.alignCenter}>
          <RedCoin className={_CLASSES.marginRight} /> 1 rood muntje = 1 minpunt
        </p>
        <AcButton callback={nextAction} theme="action">
          Start
          <span className="arrow arrow-animate ac-icon--chevron-right"></span>
        </AcButton>
      </div>
    </div>
  );
};

export default AcCatchingCoinsIntro;
