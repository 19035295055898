// Imports => React
import React, { memo, useMemo, useEffect } from 'react';
import { gsap } from 'gsap';
import classNames from 'classnames';

// Imports => Constants
// import { VISUALS } from '@constants';

// Imports => Utilities
import { useWindowDimensions } from '@utils/ac-window-dimensions';

// Imports => Atoms

const _CLASSES = {
  MAIN: 'ac-modal',
  MODALPARTS: {
    CONTAINER: 'ac-modal__container',
    HEADER: 'ac-modal__header',
    CONTENT: 'ac-modal__content',
    FOOTER: 'ac-modal__footer',
  },
  BACKDROP: 'ac-modal__backdrop',
  VISUAL: 'ac-loader__visual',
};

// Component
const AcModal = ({ className, modalState, slideDirection, modalType, header, content, footer }) => {
  const { height, width } = useWindowDimensions();
  const setSlideDirection = useMemo(() => {
    const direction = (slideDirection) ? slideDirection : "up";
    let sd = {};
    const toY = { y: 0, delay: 0.5, duration: 0.5, ease: "ease-in" }
    const toX = { x: 0, delay: 0.5, duration: 0.5, ease: "ease-in" }

    switch (direction.toLowerCase()) {
      case 'up': sd = { from: { y: height + 1 }, to: toY };
        break;
      case 'down': sd = { from: { y: -height + 1 }, to: toY };
        break;
      case 'left': sd = { from: { x: width + 1 }, to: toX };
        break;
      case 'right': sd = { from: { x: -width + 1 }, to: toX };
        break;
      default:
        console.log('default direction is up');
    }

    return sd;
  });

  useEffect(() => {
    gsap.set('.ac-modal', { opacity: 0 });
    gsap.set('.ac-modal__container', setSlideDirection.from);

    if (modalState) {
      document.body.classList.add('has-modal')
      showModal();
    } else {
      document.body.classList.remove('has-modal')
    }

    
  }, [modalState]);

  useEffect(() => {
    return () => {
      document.body.classList.remove('has-modal')
    }
  }, [])

  const getModalClassNames = useMemo(() => {
    return classNames(className, modalType);
  });

  const getModalContainerClassNames = useMemo(() => {
    return classNames(_CLASSES.MODALPARTS.CONTAINER);
  });

  const getModalHeaderClassNames = useMemo(() => {
    return classNames(_CLASSES.MODALPARTS.HEADER);
  });

  const getModalContentClassNames = useMemo(() => {
    return classNames(_CLASSES.MODALPARTS.CONTENT);
  });

  const getModalFooterClassNames = useMemo(() => {
    return classNames(_CLASSES.MODALPARTS.FOOTER);
  });


  const showModal = () => {
    gsap.to('.ac-modal', { opacity: 1, duration: 1, ease: "swift" });
    gsap.to('.ac-modal__container', setSlideDirection.to);
  };

  return (
    <div className={getModalClassNames}>
      <div className={getModalContainerClassNames}>
        <div className={getModalHeaderClassNames}>
          {header}
        </div>
        <div className={getModalContentClassNames}>
          {content}
        </div>
        <div className={getModalFooterClassNames}>
          {footer}
        </div>
      </div>
    </div>
  );
};

export default memo(AcModal);
