// Imports => React
import React, { useMemo } from 'react';
import classNames from 'classnames';

// Imports => Utilities
import { AcUUID } from '@utils';

const _CLASSES = {
	MAIN: 'ac-container',
	FLUID: 'ac-container--fluid',
}

// Component
const AcGridContainer = ({ className, fluid, id = AcUUID(), children }) => {
	const getStyleClassNames = useMemo(() => {
		return classNames(
			!fluid && _CLASSES.MAIN,
			fluid && _CLASSES.FLUID,
			className
		);
	}, [fluid, className]);
	return (
		<div className={getStyleClassNames} id={id}>
			{children}
		</div>
	);
};

export default React.memo(AcGridContainer);
